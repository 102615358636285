import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import { InputProperties } from './InputProperties';

export function InputTextField({
	name, control, ...others
}: InputProperties & TextFieldProps): JSX.Element {
	return (
		<Controller
			name={name}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			control={control}
			render={({
				field: { onBlur, onChange, value },
				fieldState: { error }
				// formState
			}) => (
				<TextField
					helperText={error ? error.message : null}
					error={!!error}
					onBlur={onBlur}
					onChange={onChange}
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					value={value}
					variant='outlined'
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...others}
				/>
			)}
		/>
	);
}
