import {
	Box,
	CircularProgress, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../hooks/useAuth';
import NarrowLayout from '../components/NarrowLayout';
import { Page } from '../../../components/Page';

export function Logout(): JSX.Element {
	const auth = useAuth();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		let timer: number;
		async function doAsync() {
			try {
				await auth.logout();
				timer = window.setTimeout(() => {
					navigate('/', { replace: true });
				}, 1000);
			}
			catch (error) {
				console.log('Logout error', error);
			}
			finally {
				setIsLoading(false);
			}
		}
		void doAsync();

		return () => void (timer != null && window.clearTimeout(timer));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Page title='Logout'>
			<NarrowLayout>
				<Box sx={{ p: 5 }}>
					<Typography variant='h5' align='center' sx={{ pb: 5 }}>
						{isLoading ? 'Logging out' : 'Redirecting'}
						...
					</Typography>
					<Typography variant='h5' align='center'>
						<CircularProgress />
					</Typography>
				</Box>
			</NarrowLayout>
		</Page>
	);
}
