import { AuthedApiService } from '../../../services/AuthedApiService';
import { Wallet } from '../data/Wallet';

export type WalletAddProperties = Omit<Wallet, 'id' | 'owner'>;

export interface WalletEditProperties {
	name?: string;
	pools?: string[];
}

type WalletListResponse = Wallet[];

interface Balance {
	balance: number;
}

interface PoolBalance extends Balance {
	name: string;
}

export type WalletBalance = {
	total: Balance;
	blockchain: Balance;
	pools: PoolBalance[];
};

export class WalletService {
	static add(properties: WalletAddProperties): Promise<void> {
		return AuthedApiService.post('/wallet-watch/wallets', properties);
	}

	static get(id: string): Promise<Wallet> {
		return AuthedApiService.get<Wallet>(`/wallet-watch/wallets/${id}`);
	}

	static edit(id: string, properties: WalletEditProperties): Promise<void> {
		return AuthedApiService.patch(`/wallet-watch/wallets/${id}`, {
			name: properties.name,
			pools: properties.pools
		});
	}

	static remove(id: string): Promise<void> {
		return AuthedApiService.delete(`/wallet-watch/wallets/${id}`);
	}

	static list(): Promise<Wallet[]> {
		return AuthedApiService.get<WalletListResponse>('/wallet-watch/wallets');
	}

	static getBalance(id: string): Promise<WalletBalance> {
		return AuthedApiService.get<WalletBalance>(`/wallet-watch/wallets/${id}/balance`);
	}
}
