import React from 'react';
import {
	Box, Typography
} from '@mui/material';

import { Page } from '../../components/Page';
import { AppSelector } from './AppSelector';

export function LandingPage(): JSX.Element {
	return (
		<Page>
			<Box sx={{ pt: 5, pb: 5 }}>
				<Typography
					variant='h3'
					align='center'
				>
					Welcome to
				</Typography>
				<Typography
					variant='h1'
					align='center'
				>
					Proto Suite
				</Typography>
			</Box>
			<AppSelector />
		</Page>
	);
}
