import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Shell } from '../../layout/Shell/Shell';
import { NotFound } from '../NotFound/NotFound';
import { Login } from './views/Login';
import { Logout } from './views/Logout';
import { Register } from './views/Register';

export function Auth(): JSX.Element {
	return (
		<Routes>
			<Route
				path='/*'
				element={(
					<Shell />
				)}
			>
				<Route path='register' element={<Register />} />
				<Route path='login' element={<Login />} />
				<Route path='logout' element={<Logout />} />
				<Route path='*' element={<NotFound />} />
			</Route>
		</Routes>
	);
}
