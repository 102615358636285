import { ApiService } from '../../../services/ApiService';

export type IsUsernameAvailableResponse = {
	usernameIsAvailable: boolean;
};

export class AuthApiService {
	public static async isUsernameAvailable(username: string): Promise<boolean> {
		const response = await ApiService.postRequest<IsUsernameAvailableResponse>('/accounts/is-username-available', {
			username
		});

		return response.usernameIsAvailable;
	}
}
