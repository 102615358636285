import React from 'react';
import {
	Add,
	CheckBoxOutlineBlankOutlined,
	CheckBoxOutlined,
	HourglassEmpty,
	HourglassTop
} from '@mui/icons-material';

import { ColorIconChipData } from '../components/ColorIconChip';
import { MediaRequestState } from './MediaRequestState';

export const StateChips: Record<MediaRequestState, ColorIconChipData> = {
	NEW: {
		label: 'New',
		icon: <Add />
	},
	TODO: {
		label: 'Todo',
		icon: <CheckBoxOutlineBlankOutlined />,
		color: 'primary'
	},
	NOT_YET_AVAILABLE: {
		label: 'Not yet available',
		icon: <HourglassTop />,
		color: 'error'
	},
	IN_PROGRESS: {
		label: 'In progress',
		icon: <HourglassEmpty />
	},
	DONE: {
		label: 'Done',
		icon: <CheckBoxOutlined />,
		color: 'success'
	}
};
