import { ApiError } from './ApiError';
import { ApiService } from './ApiService';
import { AuthEngine } from './AuthEngine';
import { TokenData } from './TokenData';

type AuthorizationHeaders = {
	Authorization?: string;
};

export class AuthedApiService {
	public static async delete<T = unknown>(url: string, headers?: Record<string, string>): Promise<T> {
		let response;
		try {
			response = await ApiService.deleteRequest<T>(url, {
				...headers,
				...this.getAuthorizationHeader()
			});
		}
		catch (error) {
			const canBeRetried = await this.errorHandler(error);
			if (canBeRetried) {
				return this.delete<T>(url, {
					...headers,
					...this.getAuthorizationHeader()
				});
			}

			throw error;
		}

		return response;
	}

	public static async get<T = unknown>(url: string, parameters?: string | string[][] | Record<string, string> | URLSearchParams | undefined, headers?: Record<string, string>): Promise<T> {
		let response;
		try {
			response = await ApiService.getRequest<T>(url, parameters, {
				...headers,
				...this.getAuthorizationHeader()
			});
		}
		catch (error) {
			const canBeRetried = await this.errorHandler(error);
			if (canBeRetried) {
				return this.get<T>(url, parameters, {
					...headers,
					...this.getAuthorizationHeader()
				});
			}

			throw error;
		}

		return response;
	}

	public static async patch<T = unknown>(url: string, data?: Record<string, unknown>, headers?: Record<string, string>): Promise<T> {
		let response;
		try {
			response = await ApiService.patchRequest<T>(url, data, {
				...headers,
				...this.getAuthorizationHeader()
			});
		}
		catch (error) {
			const canBeRetried = await this.errorHandler(error);
			if (canBeRetried) {
				return this.patch<T>(url, data, {
					...headers,
					...this.getAuthorizationHeader()
				});
			}

			throw error;
		}

		return response;
	}

	public static async post<T = unknown>(url: string, data?: Record<string, unknown>, headers?: Record<string, string>): Promise<T> {
		let response;
		try {
			response = await ApiService.postRequest<T>(url, data, {
				...headers,
				...this.getAuthorizationHeader()
			});
		}
		catch (error) {
			const canBeRetried = await this.errorHandler(error);
			if (canBeRetried) {
				return this.post<T>(url, data, {
					...headers,
					...this.getAuthorizationHeader()
				});
			}

			throw error;
		}

		return response;
	}

	static getAuthorizationHeader(): AuthorizationHeaders {
		const accessToken = AuthEngine.getAccessToken();
		if (accessToken) {
			return {
				Authorization: `Bearer ${accessToken}`
			};
		}

		return {};
	}

	/**
	 * Returns true iff request can be retried, false otherwise
	 * @param error Error to handle
	 */
	static async errorHandler(error: unknown): Promise<boolean> {
		if (error instanceof ApiError && error.errorCode === 190 && error.message === 'jwt expired') {
			console.log('Access token expired');
			// Queue for getting a new token
			await this.refreshToken();
			return true;
		}

		return false;
	}

	static async refreshToken(): Promise<void> {
		const data = await ApiService.getRequest<TokenData>('/accounts/refresh-token');

		AuthEngine.setTokenData(data);
	}
}
