import { CircularProgress, TableCell, TableRow } from '@mui/material';
import React, { useMemo } from 'react';

import { UseQueryResult } from '@tanstack/react-query/src/types';
import { ApiError } from '../../../services/ApiError';
import { Wallet } from '../data/Wallet';
import { WalletBalance } from '../services/WalletService';
import { useRatesQuery, useWalletsBalancesQueries } from '../WalletWatch.queries';

interface Properties {
	columns: number;
	wallets: Wallet[];
}

export function WalletTotalRow({ columns, wallets }: Properties): JSX.Element {
	const balances = useWalletsBalancesQueries(wallets.map(wallet => wallet.id));
	const rates = useRatesQuery('USD');

	const balancesLoading = useMemo(() => balances.some(query => query.isLoading), [balances]);

	const sum = useMemo<number>(() => {
		if (balances.length === 0 || !rates.data) {
			return 0;
		}

		let sum = 0;
		for (const [i, wallet] of wallets.entries()) {
			const symbolOfWallet = wallet.symbol;
			const balance: UseQueryResult<WalletBalance, ApiError> = balances[i] as UseQueryResult<WalletBalance, ApiError>;
			sum += (rates.data[symbolOfWallet] ?? 0) * (balance.data?.total.balance ?? 0);
		}

		return sum;
	}, [balances, rates.data, wallets]);

	return (
		<TableRow
			key='sum-of-wallets'
			sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
		>
			<TableCell colSpan={columns - 2}>
				Total
			</TableCell>
			<TableCell
				colSpan={2}
				align='right'
			>
				{(balancesLoading || rates.isLoading) && <CircularProgress size='1.5rem' />}
				{sum && (
					<strong>
						$&nbsp;
						{sum.toFixed(2)}
					</strong>
				)}
			</TableCell>
		</TableRow>
	);
}
