import React from 'react';
import {
	Album, Delete, Download, FiberManualRecord, SubtitlesOff
} from '@mui/icons-material';

import { ColorIconChipData } from '../components/ColorIconChip';
import { MediaRequestAction } from './MediaRequestAction';

export const ActionChips: Record<MediaRequestAction, ColorIconChipData> = {
	DELETE: {
		label: 'Delete',
		icon: <Delete />,
		color: 'error'
	},
	RECORD: {
		label: 'Record',
		icon: <FiberManualRecord />,
		color: 'error'
	},
	RIP: {
		label: 'Rip',
		icon: <Album />
	},
	IMPORT: {
		label: 'Import',
		icon: <Download />
	},
	MISSING_SUBTITLES: {
		label: 'Missing subtitles',
		icon: <SubtitlesOff />,
		color: 'info'
	}
};
