import { DateService } from './DateService';
import { IAstroDay } from './IAstroDay';
import { IAstroDayFactory } from './IAstroDayFactory';
import { IAstroDaysService } from './IAstroDaysService';

export class AstroDaysService implements IAstroDaysService {
	private readonly dateService: DateService;

	private readonly astroDayFactory: IAstroDayFactory;

	constructor(dateService: DateService, astroDayFactory: IAstroDayFactory) {
		this.dateService = dateService;
		this.astroDayFactory = astroDayFactory;
	}

	public getDays(date: Date, daysAround: number, latitude: number, longitude: number, altitude?: number): IAstroDay[] {
		const dateRange = this.dateService.getDateRangeAroundDate(date, daysAround);

		const allDates = this.dateService.getDaysArray(dateRange.startDate, dateRange.endDate);
		const yearData: IAstroDay[] = allDates.map(date => this.astroDayFactory.getDay(date, latitude, longitude, altitude));

		return yearData;
	}
}
