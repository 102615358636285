import React, { MouseEventHandler, ReactElement } from 'react';
import { Chip } from '@mui/material';
import { ChipTypeMap } from '@mui/material/Chip/Chip';

export interface ColorIconChipData {
	label: string;
	icon: ReactElement;
	color?: ChipTypeMap['props']['color'];
}

interface Properties {
	data: ColorIconChipData;
	onClick?: MouseEventHandler<HTMLElement>;
	variant?: ChipTypeMap['props']['variant'];
}

ColorIconChip.defaultProps = {
	onClick: undefined,
	variant: undefined
};

export function ColorIconChip({
	data,
	onClick,
	variant
}: Properties): JSX.Element {
	return (
		<Chip
			label={data.label}
			icon={data.icon}
			color={data.color ?? 'default'}
			variant={variant ?? 'outlined'}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...(onClick && { onClick })}
		/>
	);
}
