import { GetTimesResult, getMoonTimes, getTimes } from 'suncalc';

// Clone of suncalc.GetTimesResult
export interface SunTimes {
	dawn?: Date; // dawn (morning nautical twilight ends, morning civil twilight starts)
	dusk?: Date; // dusk (evening nautical twilight starts)
	goldenHour?: Date; // evening golden hour starts
	goldenHourEnd?: Date; // morning golden hour (soft light, best time for photography) ends
	nadir: Date; // nadir (darkest moment of the night, sun is in the lowest position)
	nauticalDawn?: Date; // nautical dawn (morning nautical twilight starts)
	nauticalDusk?: Date; // nautical dusk (evening astronomical twilight starts)
	night?: Date; // night starts (dark enough for astronomical observations)
	nightEnd?: Date; // night ends (morning astronomical twilight starts)
	solarNoon: Date; // solar noon (sun is in the highest position)
	sunrise?: Date; // sunrise (top edge of the sun appears on the horizon)
	sunriseEnd?: Date; // sunrise ends (bottom edge of the sun touches the horizon)
	sunset?: Date; // sunset (sun disappears below the horizon, evening civil twilight starts)
	sunsetStart?: Date; // sunset starts (bottom edge of the sun touches the horizon)
}

// Clone of suncalc.GetMoonTimes
export interface MoonTimes {
	rise: Date;
	set: Date;
	alwaysUp?: true;
	alwaysDown?: true;
}

export class SunCalcFacade {
	getSunTimes(date: Date, latitude: number, longitude: number, altitude?: number): SunTimes {
		const times = getTimes(date, latitude, longitude, altitude);

		const mapped: SunTimes = {
			solarNoon: times.solarNoon,
			nadir: times.nadir
		};

		for (const key of Object.keys(times)) {
			const date = times[key as keyof GetTimesResult];
			if (date.toString() !== 'Invalid Date') {
				mapped[key as keyof GetTimesResult] = date;
			}
		}

		return mapped;
	}

	getMoonTimes(date: Date, latitude: number, longitude: number): MoonTimes {
		const times = getMoonTimes(date, latitude, longitude);
		return times;
	}
}
