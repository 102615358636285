import { AuthedApiService } from '../../../services/AuthedApiService';

export class BalanceService {
	static async getSupportedPools(symbol: string): Promise<string[]> {
		const symbolToPools = await AuthedApiService.get<Record<string, string[]>>('/wallet-watch/balance/supported-pools', {
			symbol
		});

		return symbolToPools[symbol] ?? [];
	}
}
