import React, { MouseEvent } from 'react';
import {
	Box, Popper, Stack
} from '@mui/material';
import { QuestionMark } from '@mui/icons-material';

import { ColorIconChip, ColorIconChipData } from './ColorIconChip';

interface Properties {
	value: string;
	map: Record<string, ColorIconChipData>;
	onOptionClick?(newValue: string): void;
}

LookupChip.defaultProps = {
	onOptionClick: undefined
};

function getChipData(value: string, map: Record<string, ColorIconChipData>): ColorIconChipData {
	let chipData = { ...map[value] };
	if (!chipData) {
		chipData = {
			label: value || 'Unknown',
			icon: <QuestionMark />,
			color: 'warning'
		};
	}

	return chipData;
}

/**
 *
 * @param value Lookup value
 * @param map Mapping from value to ColorIconChipData
 * @param onChange Iff provided, will render chip as clickable with Popper of the options from the provided map.
 * @constructor
 */
export function LookupChip({ value, map, onOptionClick }: Properties): JSX.Element {
	const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorElement);

	const handleClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorElement(anchorElement ? null : event.currentTarget);
	};

	const handleOptionClick = (newValue: string) => {
		setAnchorElement(null);
		if (onOptionClick) {
			onOptionClick(newValue);
		}
	};

	const chipData = getChipData(value, map);
	if (onOptionClick) {
		chipData.label = `${getChipData(value, map).label}${open ? ' ᐁ' : ' ᐊ'}`;
	}

	const options = [];
	if (onOptionClick) {
		for (const [key, data] of Object.entries(map)) {
			if (key === value) {
				options.push(<ColorIconChip key={key} data={data} variant='filled' />);
			}
			else {
				options.push(<ColorIconChip key={key} data={data} onClick={() => handleOptionClick(key)} />);
			}
		}
	}

	return (
		<>
			<ColorIconChip
				data={chipData}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(onOptionClick && { onClick: handleClick })}
			/>
			{onOptionClick && (
				<Popper open={open} anchorEl={anchorElement}>
					<Box
						sx={{
							border: 1,
							borderRadius: '5px',
							p: 1,
							bgcolor: 'background.paper'
						}}
					>
						<Stack spacing={0.5}>
							{options.map(option => option)}
						</Stack>
					</Box>
				</Popper>
			)}
		</>
	);
}
