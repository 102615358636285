import {
	Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import React, { useMemo } from 'react';

import { LoadingPlaceholder } from '../../../components/LoadingPlaceholder';
import { Page } from '../../../components/Page';
import { ApiErrorAlert } from '../../Auth/components/ApiErrorAlert';
import { WalletListRow } from '../components/WalletListRow';
import { WalletTotalRow } from '../components/WalletTotalRow';
import { useCryptoSymbolsQuery, useWalletsQuery } from '../WalletWatch.queries';

export function WalletList(): JSX.Element {
	const wallets = useWalletsQuery();
	const sortedWallets = useMemo(() => wallets.data?.sort((a, b) => a.symbol.localeCompare(b.symbol)), [wallets.data]);

	const cryptoSymbols = useCryptoSymbolsQuery();

	return (
		<Page title='Wallet Watch'>
			<TableContainer component={Paper} elevation={0}>
				<Table sx={{ minWidth: 650 }} aria-label='List of Wallets' size='small'>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell />
							<TableCell>Coin</TableCell>
							<TableCell>Address</TableCell>
							<TableCell align='right'>Pools</TableCell>
							<TableCell align='right'>Blockchain</TableCell>
							<TableCell align='right'>Total</TableCell>
							<TableCell align='right'>Rate</TableCell>
							<TableCell align='right'>USD</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedWallets && sortedWallets.map(wallet => (
							<WalletListRow
								key={wallet.id}
								wallet={wallet}
								cryptoSymbol={cryptoSymbols.data && cryptoSymbols.data[wallet.symbol]}
							/>
						))}
						{sortedWallets && (
							<WalletTotalRow
								columns={9}
								wallets={sortedWallets}
							/>
						)}
					</TableBody>
				</Table>
				<LoadingPlaceholder
					isLoading={wallets.isLoading}
					label='Loading Wallets...'
				/>
				{wallets.error && (
					<ApiErrorAlert apiError={wallets.error} sx={{ m: 2 }} />
				)}
				{cryptoSymbols.error && (
					<ApiErrorAlert apiError={cryptoSymbols.error} sx={{ m: 2 }} />
				)}
			</TableContainer>
		</Page>
	);
}
