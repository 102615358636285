import { AstroTimeEvent } from './AstroTimeEvent';

export class AstroTimeEventSorter {
	public sortByDate(timeEvents: AstroTimeEvent[]): AstroTimeEvent[] {
		const sorted = [...timeEvents].sort((a, b) => {
			if (a.date < b.date) {
				return -1;
			}
			if (b.date > a.date) {
				return 1;
			}

			return 0;
		});

		return sorted;
	}
}
