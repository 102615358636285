import { AuthedApiService } from '../../../services/AuthedApiService';

export type MeResponse = {
	username: string;
};

export type RolesResponse = {
	id: string;
	rank: number;
	name: string;
	domain: string;
}[];

export class AccountService {
	static getMe(): Promise<MeResponse> {
		return AuthedApiService.get<MeResponse>('/accounts/me');
	}

	static getRoles() {
		return AuthedApiService.get<RolesResponse>('/accounts/roles');
	}
}
