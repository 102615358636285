export class RoleService {
	private readonly roles: string[];

	constructor(accountRoles: string[]) {
		this.roles = accountRoles;
	}

	public can(allowedRoles?: string[]): boolean {
		if (!allowedRoles) {
			return false;
		}

		// Check if there is a direct overlap
		const overlap = [...new Set(this.roles)].filter(role => new Set(allowedRoles).has(role));
		if (overlap.length > 0) {
			return true;
		}

		return false;
	}
}
