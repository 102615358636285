import {
	CircularProgress, TableCell, TableRow, Typography
} from '@mui/material';
import React from 'react';

import { EditIconButtonLink } from '../../../components/EditIconButtonLink';
import { CryptoSymbol } from '../data/Symbols';
import { Wallet } from '../data/Wallet';
import { CryptoInfo } from './CryptoInfo';
import { useRatesQuery, useWalletBalanceQuery } from '../WalletWatch.queries';

interface Properties {
	wallet: Wallet;
	cryptoSymbol?: CryptoSymbol;
}

WalletListRow.defaultProps = {
	cryptoSymbol: undefined
};

export function WalletListRow({ cryptoSymbol, wallet }: Properties): JSX.Element {
	const balance = useWalletBalanceQuery(wallet.id);
	const rates = useRatesQuery('USD');

	return (
		<TableRow
			sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
		>
			<TableCell>
				{wallet.name}
			</TableCell>
			<TableCell align='right'>
				<EditIconButtonLink to={`/wallet-watch/wallets/edit/${wallet.id}`} />
			</TableCell>
			{/* <TableCell>{wallet.symbol}</TableCell> */}
			<TableCell>
				{cryptoSymbol && <CryptoInfo cryptoSymbol={cryptoSymbol} />}
				{!cryptoSymbol && <Typography>{wallet.symbol}</Typography>}
			</TableCell>
			<TableCell>
				{wallet.address.slice(0, 6)}
				...
				{wallet.address.slice(-6)}
			</TableCell>
			<TableCell align='right'>
				{!balance.isLoading && (balance.data?.pools?.reduce((sum, pool) => pool.balance + sum, 0).toFixed(4) ?? '-')}
			</TableCell>
			<TableCell align='right'>
				{!balance.isLoading && (balance.data?.blockchain?.balance?.toFixed(4) ?? '-')}
			</TableCell>
			<TableCell align='right'>
				{balance.isLoading && <CircularProgress size='1.5rem' />}
				{!balance.isLoading && (balance.data?.total.balance?.toFixed(4) ?? '-')}
			</TableCell>
			<TableCell align='right'>
				{!rates.isLoading && ((rates.data && rates.data[wallet.symbol] && (
					(rates.data[wallet.symbol] ?? 0).toFixed(2)
				)) ?? '-')}
			</TableCell>
			<TableCell align='right'>
				{(!balance.isLoading && !rates.isLoading) && ((balance.data && rates.data && rates.data[wallet.symbol] && (
					((rates.data[wallet.symbol] ?? 0) * balance.data.total.balance).toFixed(2)
				)) ?? '-')}
			</TableCell>
		</TableRow>
	);
}
