import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
	Box, IconButton, Paper, Stack, Typography
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { InputAutocompleteMultiple } from '../../../components/form/InputAutocompleteMultiple';
import { InputCheckbox } from '../../../components/form/InputCheckbox';
import { InputCustomList } from '../../../components/form/InputCustomList';
import { InputTextField } from '../../../components/form/InputTextField';
import { LoadingPlaceholder } from '../../../components/LoadingPlaceholder';
import { Page } from '../../../components/Page';
import { RemoveTextConfirmationDialog } from '../../../components/RemoveTextConfirmationDialog';
import { ApiErrorAlert } from '../../Auth/components/ApiErrorAlert';
import { Habit } from '../data/Habit';
import { AddEditHabitFormData, HabitSchema } from '../data/HabitSchema';
import {
	useHabitComposeOptionsQuery,
	useHabitEditMutation,
	useHabitQuery,
	useHabitRemoveMutation
} from '../Tracker.queries';

export function EditHabit(): JSX.Element {
	const { id: habitId } = useParams();

	const navigate = useNavigate();

	const [options, setOptions] = useState<string[]>([]);
	const [selectedHabitComposeOptions, setSelectedHabitComposeOptions] = useState<Habit[]>([]);

	const {
		handleSubmit, control, reset, setValue, formState: {
			errors, isDirty, isValid, isSubmitting
		}
	} = useForm<AddEditHabitFormData>({
		mode: 'all',
		resolver: yupResolver(HabitSchema)
	});

	if (Object.keys(errors).length > 0) {
		console.log(`Validation errors: ${JSON.stringify(errors, null, 4)}`);
	}

	const habit = useHabitQuery(habitId ?? '', !!habitId);
	const composeOptions = useHabitComposeOptionsQuery(habitId, !!habitId);

	useEffect(() => {
		const theHabitComposeOptions = habit.data?.composedOf?.map(id => composeOptions.data?.find(habit => habit.id === id) ?? {
			id: 'unknown',
			owner: 'Unknown owner',
			name: 'Unknown habit',
			directEntry: true,
			requireCount: false
		}) ?? [];
		setSelectedHabitComposeOptions(theHabitComposeOptions);
	}, [composeOptions.data, habit.data?.composedOf]);

	useEffect(() => {
		reset({
			...habit.data,
			composedOf: selectedHabitComposeOptions
		});
		setOptions(habit.data?.options || []);
	}, [reset, habit.data, selectedHabitComposeOptions]);

	const edit = useHabitEditMutation();
	const remove = useHabitRemoveMutation();

	const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

	const handleRemoveDialogOpen = () => {
		setRemoveDialogOpen(true);
	};

	const handleRemoveDialogClose = async (confirmed: boolean) => {
		if (confirmed) {
			remove.mutate(habitId ?? '', {
				onSuccess: () => navigate('/tracker/habits/manage')
			});
		}

		setRemoveDialogOpen(false);
	};

	const onValid = async (data: AddEditHabitFormData) => {
		await edit.mutateAsync({ habitId: habitId ?? '', data }, {
			onSuccess: () => navigate('/tracker/habits/manage')
		});
	};

	return (
		<Page title={`Edit Habit ${habit.data?.name ?? ''} - Tracker`}>
			{habit.data && (
				<RemoveTextConfirmationDialog
					open={removeDialogOpen}
					onClose={handleRemoveDialogClose}
					itemType='habit'
					confirmText={`${habit.data.name}`}
				/>
			)}
			{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
			<form onSubmit={handleSubmit(onValid)}>
				<Stack spacing={3}>
					<Box
						display='flex'
					>
						<Typography variant='h6'>
							Edit habit
						</Typography>
						<Box sx={{ flexGrow: 1 }} />
						{!habit.isLoading && (
							<IconButton
								onClick={handleRemoveDialogOpen}
								aria-label='remove'
								color='warning'
								size='small'
								sx={{
									textTransform: 'none'
								}}
							>
								<Delete fontSize='small' />
							</IconButton>
						)}
					</Box>
					{habit.isLoading ? (
						<LoadingPlaceholder
							isLoading={habit.isLoading}
							label='Loading Habit...'
						/>
					) : (
						<>
							<InputTextField
								name='name'
								control={control}
								label='Name'
							/>
							<InputTextField
								name='ordering'
								control={control}
								label='Ordering'
								type='number'
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*'
								}}
							/>
							<InputCheckbox
								name='directEntry'
								control={control}
								label='Allow direct tracking'
							/>
							<InputCheckbox
								name='requireCount'
								control={control}
								label='Require count to be set when tracking'
							/>
							<Paper
								variant='outlined'
								sx={{
									p: 2
								}}
							>
								<InputCustomList
									name='options'
									label='Options - Require one when tracking'
									setValue={setValue}
									items={options}
									setItems={setOptions}
								/>
							</Paper>
							<InputAutocompleteMultiple<Habit>
								name='composedOf'
								label='Composed of'
								setValue={setValue}
								options={composeOptions.data ?? []}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								getOptionLabel={option => option.name}
								selectedOptions={selectedHabitComposeOptions}
								setSelectedOptions={setSelectedHabitComposeOptions}
							/>
							{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
							<LoadingButton
								size='large'
								type='submit'
								variant='contained'
								disabled={!isDirty || !isValid}
								loading={isSubmitting}
							>
								Save
							</LoadingButton>
						</>
					)}
					{habit.error && (
						<ApiErrorAlert apiError={habit.error} />
					)}
					{composeOptions.error && (
						<ApiErrorAlert apiError={composeOptions.error} />
					)}
					{edit.error && (
						<ApiErrorAlert apiError={edit.error} />
					)}
					{remove.error && (
						<ApiErrorAlert apiError={remove.error} />
					)}
				</Stack>
			</form>
		</Page>
	);
}
