import { AstroTimeEvent } from './AstroTimeEvent';
import { IAstroDay } from './IAstroDay';
import { AstroPhase } from './AstroPhase';

export class SunDay implements IAstroDay {
	public readonly date;

	public readonly sunTimes;

	public readonly phases;

	constructor(date: Date, sunTimes: AstroTimeEvent[], phases: AstroPhase[]) {
		this.date = date;
		this.sunTimes = sunTimes;
		this.phases = phases;
	}
}
