import { MediaRequestState } from './MediaRequestState';
import { MediaRequest } from './MediaRequest';

export function mediaRequestSorter(a: MediaRequest, b: MediaRequest): number {
	const stateRankOfA = Object.keys(MediaRequestState).indexOf(a.state);
	const stateRankOfB = Object.keys(MediaRequestState).indexOf(b.state);

	const stateDiff = stateRankOfA - stateRankOfB;
	if (stateDiff !== 0) {
		// Lower rank sorted first
		return stateDiff;
	}

	if (a.state === MediaRequestState.DONE && a.state === b.state) {
		// Higher timestamp sorted first
		const aDoneAt = a.doneAt ? Date.parse(a.doneAt) : Number.MAX_SAFE_INTEGER;
		const bDoneAt = b.doneAt ? Date.parse(b.doneAt) : Number.MAX_SAFE_INTEGER;

		const timeDiff = bDoneAt - aDoneAt;
		return timeDiff;
	}

	const nameDiff = a.name.localeCompare(b.name);
	return nameDiff;
}
