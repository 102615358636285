import React, { useEffect, useState } from 'react';
import {
	Box, Typography
} from '@mui/material';
import useGeolocation from 'react-hook-geolocation';

import { LoadingPlaceholder } from '../../../components/LoadingPlaceholder';
import { Page } from '../../../components/Page';
import { GeolocationPositionErrorAlert } from '../components/GeolocationPositionErrorAlert';
import { MoonTimes, SunCalcFacade, SunTimes } from '../services/SunCalcFacade';

const sunCalcFacade = new SunCalcFacade();

export function Debug(): JSX.Element {
	console.log('SunnyShine');
	const geolocation = useGeolocation();

	const [sunTimesData, setSunTimesData] = useState<SunTimes>();
	const [moonTimesData, setMoonTimesData] = useState<MoonTimes>();

	useEffect(() => {
		const { latitude, longitude, altitude } = geolocation;
		if (latitude && longitude) {
			const sun = sunCalcFacade.getSunTimes(new Date(), latitude, longitude, altitude ?? undefined);
			console.log('Sun times data', sun);
			setSunTimesData(sun);
			const moon = sunCalcFacade.getMoonTimes(new Date(), latitude, longitude);
			console.log('Moon times data', moon);
			setMoonTimesData(moon);
		}
	}, [geolocation]);

	return (
		<Page title='Debug - Sunny Shine'>
			<Typography variant='h4'>
				Debug
			</Typography>
			<Typography variant="subtitle1" gutterBottom>
				Based on GPS position from browser
			</Typography>
			{geolocation.error && <GeolocationPositionErrorAlert error={geolocation.error} />}
			{!geolocation.error && !(geolocation.latitude && geolocation.longitude) && (
				<LoadingPlaceholder
					isLoading
					label='Waiting for location'
				/>
			)}
			{geolocation.latitude && geolocation.longitude && (
				<Box
					sx={{
						p: 2,
						border: '1px dashed grey',
						borderRadius: '5px',
						mb: 2
					}}
				>
					<Typography variant="h6">
						GPS location
					</Typography>
					<pre>{JSON.stringify(geolocation, null, 4)}</pre>
				</Box>
			)}
			{sunTimesData && (
				<Box
					sx={{
						p: 2,
						border: '1px dashed grey',
						borderRadius: '5px',
						mb: 2
					}}
				>
					<Typography variant="h6">
						Sun times
					</Typography>
					<pre>{JSON.stringify(sunTimesData, null, 4)}</pre>
				</Box>
			)}
			{moonTimesData && (
				<Box
					sx={{
						p: 2,
						border: '1px dashed grey',
						borderRadius: '5px'
					}}
				>
					<Typography variant="h6">
						Moon times
					</Typography>
					<pre>{JSON.stringify(moonTimesData, null, 4)}</pre>

				</Box>
			)}
		</Page>
	);
}
