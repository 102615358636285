import React, { PropsWithChildren, useMemo } from 'react';

import { useAccountRoles } from '../hooks/useAccountRoles';
import { RoleService } from '../services/RoleService';

export function ShowIfCan(properties: PropsWithChildren<{ roles: string[] }>): JSX.Element | null {
	const accountRoles = useAccountRoles();
	const accountRoleService = useMemo(() => (new RoleService(accountRoles)), [accountRoles]);

	const { children, roles } = properties;

	if (!children || !roles || !accountRoleService.can(roles)) {
		return null;
	}

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{ children }
		</>
	);
}
