import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
	Stack
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { InputTextField } from '../../../components/form/InputTextField';
import { InputPasswordFieldWithToggle } from '../../../components/form/InputPasswordFieldWithToggle';
import { useAuth } from '../../../hooks/useAuth';
import { ApiError } from '../../../services/ApiError';
import { ApiErrorAlert } from '../components/ApiErrorAlert';
import { AuthApiService } from '../services/AuthApiService';

const RegisterSchema = yup.object({
	username: yup.string()
		.min(4, 'Minimum length is 4 characters')
		.required('Username is required')
		.test(
			'is-username-available',
			'Username is not available',
			async value => {
				if (value) {
					return AuthApiService.isUsernameAvailable(value);
				}

				return true;
			}
		),
	password: yup.string()
		.min(4, 'Minimum length is 4 characters')
		.required('Password is required'),
	passwordConfirmation: yup.string()
		.oneOf([yup.ref('password')], 'Passwords must match')
});

type RegisterFormData = yup.InferType<typeof RegisterSchema>;

export function RegisterForm(): JSX.Element {
	const auth = useAuth();
	const navigate = useNavigate();
	const [apiError, setApiError] = useState<ApiError | null>();

	const {
		handleSubmit, control, formState: {
			errors, isDirty, isValid, isSubmitting
		}
	} = useForm<RegisterFormData>({
		mode: 'all',
		resolver: yupResolver(RegisterSchema)
	});

	if (Object.keys(errors).length > 0) {
		console.log(`Validation errors: ${JSON.stringify(errors, null, 4)}`);
	}

	const onSubmit = async (data: RegisterFormData) => {
		setApiError(null);
		try {
			await auth.register(data.username, data.password);
			await auth.login(data.username, data.password, false);
			navigate('/', { replace: true });
		}
		catch (error) {
			if (error instanceof ApiError) {
				setApiError(error);
			}
			console.error('Register error', error);
		}
	};

	return (
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={3}>
				<InputTextField
					name='username'
					control={control}
					fullWidth
					autoFocus
					autoComplete='username'
					label='Username'
				/>
				<InputPasswordFieldWithToggle
					name='password'
					control={control}
					fullWidth
					label='Password'
				/>
				<InputTextField
					name='passwordConfirmation'
					control={control}
					fullWidth
					label='Confirm password'
					type='password'
				/>

				{apiError && (
					<ApiErrorAlert apiError={apiError} />
				)}

				<LoadingButton
					fullWidth
					size='large'
					type='submit'
					variant='contained'
					disabled={!isDirty || !isValid}
					loading={isSubmitting}
				>
					Register
				</LoadingButton>
			</Stack>
		</form>
	);
}
