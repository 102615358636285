import * as yup from 'yup';

export const WalletSchema = yup.object({
	name: yup.string(),
	symbol: yup.string().required('You need to select a symbol'),
	address: yup.string().required('You need to put in a wallet address for the selected coin'),
	pools: yup.array().of(yup.string().required('Can\'t be empty. Makes no sense.'))
});

export type AddWalletFormData = yup.InferType<typeof WalletSchema>;
export type EditWalletFormData = Pick<AddWalletFormData, 'name' | 'pools'>;
