import { DateTime, Duration } from 'luxon';

export class DateService {
	public getDateRangeAroundDate(date: Date, daysAround: number) {
		const theDate = DateTime.fromJSDate(date);

		const halfDays = Math.floor(daysAround / 2);
		const halfDuration = Duration.fromObject({
			days: halfDays
		});

		const startDate = theDate.minus(halfDuration).toJSDate();
		const endDate = theDate.plus(halfDuration).toJSDate();

		return {
			startDate,
			endDate
		};
	}

	public getDaysArray(start: Date, end: Date) {
		if (end < start) {
			throw new Error('end cannot be before start');
		}

		const dates = [];
		for (const dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
			dates.push(new Date(dt));
		}
		return dates;
	}
}
