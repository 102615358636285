import {
	Avatar, Chip, Stack, Tooltip, Typography
} from '@mui/material';
import React from 'react';

import { CryptoSymbol } from '../data/Symbols';

interface Properties {
	cryptoSymbol: CryptoSymbol;
}

export function CryptoInfo({ cryptoSymbol }: Properties): JSX.Element {
	return (
		<Stack
			direction='row'
			alignItems='center'
			spacing={1}
		>
			<Avatar
				alt={cryptoSymbol.name}
				src={cryptoSymbol.logo}
				sx={{ width: 32, height: 32 }}
			/>
			<Tooltip title={cryptoSymbol.name}>
				<Chip
					label={cryptoSymbol.symbol}
					size='small'
					variant='outlined'
				/>
			</Tooltip>
			<Typography>
				{cryptoSymbol.name}
			</Typography>
		</Stack>
	);
}
