import React, { useMemo } from 'react';
import {
	BrowserRouter,
	Route,
	Routes
} from 'react-router-dom';
import {
	CssBaseline, PaletteMode, ThemeProvider, useMediaQuery
} from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { createTheme } from '@mui/material/styles';
import { useStateWithLocalStorage } from './components/useStateLocalStorage';
import { ProvideAuth } from './hocs/ProvideAuth';
import { RequireAuth } from './hocs/RequireAuth';
import { Shell } from './layout/Shell/Shell';
import { Account } from './views/Account/Account';
import { Auth } from './views/Auth/Auth';
import { LandingPage } from './views/LandingPage/LandingPage';
import { MediaRequest } from './views/MediaRequest/MediaRequest';
import { NotFound } from './views/NotFound/NotFound';
import { SunnyShine } from './views/SunnyShine/SunnyShine';
import { Tracker } from './views/Tracker/Tracker';
import { WalletWatch } from './views/WalletWatch/WalletWatch';

const queryClient = new QueryClient();

function App(): JSX.Element {
	const browserDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const [modeFromStorage] = useStateWithLocalStorage('displayMode', 'automatic');

	const theme = useMemo(
		() => {
			let mode;
			if (modeFromStorage === 'automatic') {
				mode = browserDarkMode ? 'dark' : 'light';
			}
			else {
				mode = modeFromStorage;
			}

			return createTheme({
				palette: {
					mode: mode as PaletteMode
				}
			});
		},
		[browserDarkMode, modeFromStorage]
	);

	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<ProvideAuth>
					<BrowserRouter>
						<Routes>
							<Route
								path="/account/*"
								element={(
									<RequireAuth>
										<Account />
									</RequireAuth>
								)}
							/>
							<Route path="/auth/*" element={<Auth />} />
							<Route
								path="/media-request/*"
								element={(
									<RequireAuth>
										<MediaRequest />
									</RequireAuth>
								)}
							/>
							<Route
								path="/sunny-shine/*"
								element={(
									<RequireAuth>
										<SunnyShine />
									</RequireAuth>
								)}
							/>
							<Route
								path="/tracker/*"
								element={(
									<RequireAuth>
										<Tracker />
									</RequireAuth>
								)}
							/>
							<Route
								path="/wallet-watch/*"
								element={(
									<RequireAuth>
										<WalletWatch />
									</RequireAuth>
								)}
							/>
							<Route path="/" element={<Shell />}>
								<Route index element={<LandingPage />} />
								<Route path="*" element={<NotFound />} />
							</Route>
						</Routes>
					</BrowserRouter>
				</ProvideAuth>
			</ThemeProvider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
}

export default App;
