import { AstroPhase } from './AstroPhase';
import { IAstroDay } from './IAstroDay';

export class MoonDay implements IAstroDay {
	public readonly date: Date;

	public readonly phases: AstroPhase[];

	constructor(date: Date, phases: AstroPhase[]) {
		this.date = date;
		this.phases = phases;
	}
}
