import { Alert, Typography } from '@mui/material';
import React from 'react';

type Properties = {
	error: GeolocationPositionError;
};

export function GeolocationPositionErrorAlert({ error }: Properties): JSX.Element {
	return (
		<Alert variant='outlined' severity='error'>
			<Typography>
				GeolocationPositionError
			</Typography>
			<Typography variant="caption">
				{error.message}
			</Typography>
			<Typography variant="caption">
				Error Code&nbsp;
				{error.code}
			</Typography>
		</Alert>
	);
}
