import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
	Button, Paper, Stack, Typography
} from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { InputTextField } from '../../../components/form/InputTextField';
import { Page } from '../../../components/Page';
import { ApiErrorAlert } from '../../Auth/components/ApiErrorAlert';
import { AddWalletFormData, WalletSchema } from '../data/WalletSchema';
import { useWalletAddMutation } from '../WalletWatch.queries';

/*
Form
Line 1: Name
Line 2: COIN
Line 3: Address - Could be validated based on COIN, but not trivial... Is there an API for this?
*/

const defaultValues: Required<AddWalletFormData> = {
	name: '',
	symbol: '',
	address: '',
	pools: []
};

export function AddWallet(): JSX.Element {
	const navigate = useNavigate();

	const {
		handleSubmit, control, reset, formState: {
			errors, isDirty, isValid, isSubmitting
		}
	} = useForm<AddWalletFormData>({
		defaultValues,
		mode: 'all',
		resolver: yupResolver(WalletSchema)
	});

	if (Object.keys(errors).length > 0) {
		console.log(`Validation errors: ${JSON.stringify(errors, null, 4)}`);
	}

	const add = useWalletAddMutation();

	const onValid = async (data: AddWalletFormData) => {
		await add.mutateAsync(data, {
			onSuccess: () => navigate('/wallet-watch/wallets/list')
		});
	};

	return (
		<Page title='Add - Wallet Watch'>
			<Paper
				elevation={0}
				sx={{
					p: 3
				}}
			>
				{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
				<form onSubmit={handleSubmit(onValid)}>
					<Stack spacing={3}>
						<Typography variant='h6'>
							Add wallet to watch
						</Typography>
						<InputTextField
							name='name'
							control={control}
							label='Name'
						/>
						<InputTextField
							name='symbol'
							control={control}
							label='Symbol'
						/>
						<InputTextField
							name='address'
							control={control}
							label='Address'
						/>
						{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
						<LoadingButton
							size='large'
							type='submit'
							variant='contained'
							disabled={!isDirty || !isValid}
							loading={isSubmitting}
						>
							Add
						</LoadingButton>
						{add.error && (
							<ApiErrorAlert apiError={add.error} />
						)}
						<Button
							disabled={!isDirty}
							onClick={() => reset()}
							variant='outlined'
						>
							Reset
						</Button>
					</Stack>
				</form>
			</Paper>
		</Page>
	);
}
