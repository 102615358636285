import {
	Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { InputProperties } from './InputProperties';

export interface InputMultiCheckboxOption {
	label: string;
	value: string;
}

interface InputMultiCheckboxProperties {
	label: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setValue: any;
	initialValue: string[];
	options: InputMultiCheckboxOption[];
}

export function InputMultiCheckbox({
	name,
	control,
	label,
	setValue,
	initialValue,
	options
}: InputMultiCheckboxProperties & InputProperties): JSX.Element {
	const [selectedItems, setSelectedItems] = useState<unknown[]>(initialValue);

	const handleSelect = (value: unknown) => {
		const isPresent = selectedItems.includes(value);
		if (isPresent) {
			const remaining = selectedItems.filter((item: unknown) => item !== value);
			setSelectedItems(remaining);
		}
		else {
			setSelectedItems([...selectedItems, value]);
		}
	};

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		setValue(name, selectedItems, {
			shouldValidate: true,
			shouldDirty: true
		});
	}, [name, selectedItems, setValue]);

	return (
		<FormControl
			variant='outlined'
			component='fieldset'
		>
			<FormLabel component='legend'>{label}</FormLabel>
			<FormGroup>
				{options.map(option => (
					<FormControlLabel
						control={(
							<Controller
								name={name}
								render={() => (
									<Checkbox
										checked={selectedItems.includes(option.value)}
										onChange={() => handleSelect(option.value)}
									/>
								)}
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
								control={control}
							/>
						)}
						label={option.label}
						key={option.value}
					/>
				))}
			</FormGroup>
		</FormControl>
	);
}
