import { Outlet } from 'react-router-dom';
import React, { ReactNode, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { AppBarProps as MuiAppBarProperties } from '@mui/material/AppBar/AppBar';
import {
	Box, IconButton, AppBar as MuiAppBar, Paper, Toolbar
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

import { Header } from '../components/Header';
import { PersistentDrawer } from './PersistentDrawer';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: property => property !== 'open' })<{
	open?: boolean;
}>(({ theme, open }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	marginLeft: `-${drawerWidth}px`,
	...(open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		}),
		marginLeft: 0
	})
}));

interface AppBarProperties extends MuiAppBarProperties {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: property => property !== 'open'
})<AppBarProperties>(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	})
}));

interface PersistentDrawerShellProperties {
	drawerTitle?: string;
	menuItems: ReactNode;
	title?: string;
}

PersistentDrawerShell.defaultProps = {
	drawerTitle: undefined,
	title: undefined
};

export function PersistentDrawerShell({
	drawerTitle, menuItems, title
}: PersistentDrawerShellProperties): JSX.Element {
	const theme = useTheme();

	// TODO: Base initial state on screen size
	const [open, setOpen] = useState(true);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<Paper square sx={{ display: 'flex', minHeight: '100vh' }}>
			<AppBar position='fixed' open={open}>
				<Toolbar>
					<IconButton
						color='inherit'
						aria-label='Open drawer'
						onClick={handleDrawerOpen}
						edge='start'
						sx={{ mr: 2, ...(open && { display: 'none' }) }}
					>
						<MenuIcon />
					</IconButton>
					<Header title={title} />
				</Toolbar>
			</AppBar>
			<PersistentDrawer
				width={drawerWidth}
				title={drawerTitle}
				open={open}
				onClose={handleDrawerClose}
			>
				{menuItems}
			</PersistentDrawer>
			<Main open={open}>
				{/* This just takes up space to ensure the Outlet doesn't render below the real Header */}
				<Box
					sx={{
						...theme.mixins.toolbar
						// NB: This works by chance. Padding on Main offsets the size of this
					}}
				/>
				<Outlet />
			</Main>
		</Paper>
	);
}
