import { Habit } from './Habit';

/**
 * Sort by ordering (iff defined), second by name
 * @param a
 * @param b
 */
export function habitSorter(a: Habit, b: Habit): number {
	const aHasOrdering = a.ordering !== undefined;
	const bHasOrdering = b.ordering !== undefined;
	if (a.ordering !== undefined && b.ordering !== undefined) {
		if (a.ordering === b.ordering) {
			return a.name.localeCompare(b.name);
		}

		return a.ordering - b.ordering;
	}

	if (aHasOrdering && !bHasOrdering) {
		return -1;
	}

	if (!aHasOrdering && bHasOrdering) {
		return 1;
	}

	return a.name.localeCompare(b.name);
}
