import { useEffect, useState } from 'react';

import { AuthContextValue } from '../contexts/AuthContext';
import { ApiService } from '../services/ApiService';
import { AuthedApiService } from '../services/AuthedApiService';
import { AuthEngine } from '../services/AuthEngine';
import { TokenData } from '../services/TokenData';

type TokenResponse = TokenData;

export function useProvideAuth(): AuthContextValue {
	const [isAuthenticated, setIsAuthenticated] = useState(AuthEngine.isAuthenticated());

	// eslint-disable-next-line unicorn/consistent-function-scoping
	const register = async function register(username: string, password: string): Promise<void> {
		await ApiService.postRequest('/accounts/register', {
			username,
			password
		});
	};

	const login = async function login(username: string, password: string, persist: boolean): Promise<void> {
		const data = await ApiService.postRequest<TokenResponse>('/accounts/authenticate', {
			username,
			password,
			persist
		});

		AuthEngine.setTokenData(data);
		setIsAuthenticated(AuthEngine.isAuthenticated());
	};

	const logout = async function logout(): Promise<void> {
		try {
			await AuthedApiService.post('/accounts/revoke-token');
		}
		catch (error) {
			console.warn('Revoke token error', error);
		}

		AuthEngine.clearTokenData();
		setIsAuthenticated(AuthEngine.isAuthenticated());
	};

	useEffect(() => {
		const interval = window.setInterval(() => {
			setIsAuthenticated(AuthEngine.isAuthenticated());
		}, 60_000);

		return () => window.clearInterval(interval);
	}, []);

	return {
		isAuthenticated,
		register,
		login,
		logout
	};
}
