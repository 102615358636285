import { PermIdentity, Settings } from '@mui/icons-material';
import { Divider, List } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ListItemLink } from '../../components/ListItemLink';
import { ShowIfCan } from '../../hocs/ShowIfCan';
import { PersistentDrawerShell } from '../../layout/PersistentDrawerShell/PersistentDrawerShell';
import { NotFound } from '../NotFound/NotFound';
import { Start } from './views/Start';
import { TechnicalDetails } from './views/TechnicalDetails';

export function Account(): JSX.Element {
	const menu = (
		<>
			<List>
				<ListItemLink
					icon={<PermIdentity />}
					primary='Start'
					to='/account'
				/>
			</List>
			<ShowIfCan roles={['accounts:admin']}>
				<Divider />
				<List>
					<ListItemLink
						icon={<Settings />}
						primary='Technical Details'
						to='/account/technical-details'
					/>
				</List>
			</ShowIfCan>
		</>
	);

	return (
		<Routes>
			<Route
				path='/*'
				element={(
					<PersistentDrawerShell
						menuItems={menu}
						title='Account'
					/>
				)}
			>
				<Route index element={<Start />} />
				<Route path="technical-details" element={<TechnicalDetails />} />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
}
