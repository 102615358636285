import React, { Dispatch, SetStateAction, useEffect } from 'react';
import {
	Autocomplete, TextField
} from '@mui/material';

import { InputProperties } from './InputProperties';

interface InputAutocompleteMultipleProperties<T> {
	label: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setValue: any;
	options: T[];
	isOptionEqualToValue(option: T, value: T): boolean;
	getOptionLabel(option: T): string;
	selectedOptions: T[];
	setSelectedOptions: Dispatch<SetStateAction<T[]>>;
}

export function InputAutocompleteMultiple<T>({
	name,
	label,
	options,
	isOptionEqualToValue,
	getOptionLabel,
	setValue,
	selectedOptions,
	setSelectedOptions
}: InputAutocompleteMultipleProperties<T> & Omit<InputProperties, 'control'>): JSX.Element {
	const handleChange = (event: unknown, value: T[]) => {
		setSelectedOptions(value);
	};

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		setValue(name, selectedOptions, {
			shouldValidate: true,
			shouldDirty: true
		});
	}, [name, selectedOptions, setValue]);

	return (
		<Autocomplete
			autoHighlight
			filterSelectedOptions
			multiple
			options={options}
			isOptionEqualToValue={isOptionEqualToValue}
			getOptionLabel={getOptionLabel}
			onChange={handleChange}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			value={selectedOptions}
			renderInput={parameters => (
				<TextField
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...parameters}
					label={label}
				/>
			)}
		/>
	);
}
