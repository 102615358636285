import { Box, Container, Typography } from '@mui/material';
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet-async';

const version = process.env.REACT_APP_VERSION_TAG;

type Properties = {
	children: React.ReactNode;
	title?: string;
	fixed?: boolean;
	maxWidth?: Breakpoint | false;
};
export function Page({
	children,
	title,
	fixed,
	maxWidth
}: Properties): JSX.Element {
	return (
		<Container disableGutters fixed={fixed || false} maxWidth={maxWidth || false}>
			<Helmet>
				<title>
					{title ? `${title} | ` : ''}
					{' '}
					Proto Suite
				</title>
			</Helmet>
			{children}
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					mt: 3
				}}
			>
				<Typography
					variant='caption'
					sx={{
						color: 'grey.500'
					}}
				>
					{version ?? 'Unknown version'}
				</Typography>
			</Box>
		</Container>
	);
}

Page.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string
};

Page.defaultProps = {
	title: '',
	fixed: false,
	maxWidth: false
};
