import {
	AccountBalanceWallet, PermIdentity, TrackChanges, WbSunny
} from '@mui/icons-material';
import TheatersTwoToneIcon from '@mui/icons-material/TheatersTwoTone';
import React from 'react';

interface AppRoute {
	icon: React.ReactNode;
	title: string;
	to: string;
	anonymousAccessAllowed?: boolean;
	rolesAllowed?: string[];
}

export const appRoutes: AppRoute[] = [{
	icon: <PermIdentity fontSize='large' />,
	title: 'Account',
	to: '/account'
}, {
	icon: <TheatersTwoToneIcon fontSize='large' />,
	title: 'Media Request',
	to: '/media-request',
	rolesAllowed: [
		'media-request:user',
		'media-request:moderator'
	]
}, {
	icon: <WbSunny fontSize='large' />,
	title: 'Sunny Shine',
	to: '/sunny-shine/sun/year'
}, {
	icon: <TrackChanges fontSize='large' />,
	title: 'Tracker',
	to: '/tracker',
	rolesAllowed: [
		'tracker:user'
	]
}, {
	icon: <AccountBalanceWallet fontSize='large' />,
	title: 'Wallet Watch',
	to: '/wallet-watch',
	rolesAllowed: [
		'wallet-watch:user'
	]
}];

export function getAppRoutesByAuthState(isAuthenticated: boolean, roles: string[]) {
	return appRoutes.filter(route => {
		if (route.anonymousAccessAllowed) {
			return true;
		}

		if (route.rolesAllowed) {
			for (const role of roles) {
				if (route.rolesAllowed.includes(role)) {
					return isAuthenticated;
				}
			}
		}

		if (!route.rolesAllowed) {
			return isAuthenticated;
		}

		return false;
	});
}
