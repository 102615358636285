import {
	AppBar, Box, IconButton, Paper, Toolbar
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { ReactNode, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from '../components/Header';
import { ResponsiveDrawer } from './ResponsiveDrawer';

interface ResponseDrawerShellProperties {
	drawerTitle?: string;
	menuItems: ReactNode;
	title?: string;
}

ResponseDrawerShell.defaultProps = {
	drawerTitle: undefined,
	title: undefined
};

export function ResponseDrawerShell({ drawerTitle, menuItems, title }: ResponseDrawerShellProperties): JSX.Element {
	const drawerWidth = 240;

	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	return (
		<Paper square sx={{ display: 'flex', minHeight: '100vh' }}>
			<AppBar
				position='fixed'
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					ml: { sm: `${drawerWidth}px` }
				}}
			>
				<Toolbar>
					<IconButton
						color='inherit'
						aria-label='Open drawer'
						edge='start'
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: 'none' } }}
					>
						<MenuIcon />
					</IconButton>
					<Header title={title} />
				</Toolbar>
			</AppBar>
			<ResponsiveDrawer
				width={drawerWidth}
				title={drawerTitle}
				onClose={handleDrawerToggle}
				open={mobileOpen}
			>
				{menuItems}
			</ResponsiveDrawer>
			<Box
				component='main'
				sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
			>
				<Toolbar />
				<Outlet />
			</Box>
		</Paper>
	);
}
