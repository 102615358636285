import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { forwardRef, useMemo } from 'react';
import {
	NavLink, NavLinkProps, useMatch, useResolvedPath
} from 'react-router-dom';

interface AnchorNavLinkProperties {
	to: string;
}

interface ListItemLinkProperties extends AnchorNavLinkProperties {
	icon?: React.ReactElement;
	primary: string;
}

ListItemLink.defaultProps = {
	icon: null
};

function AnchorNavLink({ to }: AnchorNavLinkProperties) {
	return forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'>>((
		itemProperties,
		reference
	) => (
		<NavLink
			to={to}
			ref={reference}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...itemProperties}
			role={undefined}
		/>
	));
}

export function ListItemLink({ icon, primary, to }: ListItemLinkProperties): JSX.Element {
	const renderLink = useMemo(
		() => AnchorNavLink({ to }),
		[to]
	);

	const resolved = useResolvedPath(to);
	const match = useMatch({ path: resolved.pathname, end: true });

	return (
		<ListItemButton
			component={renderLink}
			selected={!!match}
		>
			{icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
			<ListItemText primary={primary} />
		</ListItemButton>
	);
}
