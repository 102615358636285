import { Typography } from '@mui/material';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Shell } from '../../layout/Shell/Shell';
import { NotFound } from '../NotFound/NotFound';
import { AddMediaRequests } from './views/AddMediaRequests';
import { ListMediaRequests } from './views/ListMediaRequests';

export function MediaRequest(): JSX.Element {
	return (
		<Routes>
			<Route
				path='/*'
				element={(
					<Shell
						title='Media Request'
						maxWidth='lg'
					/>
				)}
			>
				<Route index element={<ListMediaRequests />} />
				<Route path='add' element={<AddMediaRequests />} />
				<Route path='edit/:id' element={<Typography>Edit</Typography>} />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
}
