import React, { useState } from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { Check, Edit, Remove } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

import { Habit } from '../data/Habit';

interface Properties {
	habit: Habit;
	habits: Habit[];
}

export function ManageHabitsListRow({ habit, habits }: Properties): JSX.Element {
	const [composedOf] = useState<string[]>(habit.composedOf?.map(id => habits.find(habit => habit.id === id)?.name ?? 'Unknown habit') || []);

	return (
		<TableRow
			sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
		>
			<TableCell>
				{habit.name}
			</TableCell>
			<TableCell align='right'>
				<IconButton
					aria-label='edit'
					size='small'
					component={RouterLink}
					to={`/tracker/habits/edit/${habit.id}`}
					sx={{
						textTransform: 'none',
						color: 'inherit'
					}}
				>
					<Edit fontSize='small' />
				</IconButton>
			</TableCell>
			<TableCell>
				{habit.ordering}
			</TableCell>
			<TableCell>
				{habit.directEntry ? <Check /> : <Remove />}
			</TableCell>
			<TableCell>
				{habit.requireCount ? <Check /> : <Remove />}
			</TableCell>
			<TableCell>
				{habit.options?.join(', ')}
			</TableCell>
			<TableCell>
				{composedOf?.join(', ')}
			</TableCell>
		</TableRow>
	);
}
