import { DateTime } from 'luxon';

import { AstroTimeEvent } from './AstroTimeEvent';
import { AstroPhase } from './AstroPhase';

export class AstroPhaseFactory {
	public getPhases(timeEvents: AstroTimeEvent[]): AstroPhase[] {
		// TODO: Handle the first element, because what's the state before the first 'change' of the day?
		// The correct answer is: Whatever was last the previous day

		const phases: AstroPhase[] = [];

		const startOfDay = DateTime.fromJSDate(timeEvents[0].date).startOf('day');
		// Determine first phase; HACK: We're just using whatever is defined for 'last' on today
		const lastEntry = timeEvents[timeEvents.length - 1];
		phases.push({
			description: lastEntry.description,
			startAt: 0,
			duration: Math.round(DateTime.fromJSDate(timeEvents[0].date).diff(startOfDay).as('seconds'))
		});

		let secondsPassed = phases[0].duration;
		for (let i = 0; i < timeEvents.length; i++) {
			const { date, description } = timeEvents[i];
			const startAtSecond = secondsPassed;

			const hasNext = (i + 1) in timeEvents;
			const dateOfNext = hasNext ? DateTime.fromJSDate(timeEvents[i + 1].date) : DateTime.fromJSDate(date).endOf('day');
			const trueLengthInSeconds = dateOfNext.diff(DateTime.fromJSDate(date)).as('seconds');

			const trueStartAtSecond = Math.round(DateTime.fromJSDate(date).diff(startOfDay).as('seconds'));
			const trueStartIsEarly = trueStartAtSecond < secondsPassed;
			const trueStartIsLate = trueStartAtSecond > secondsPassed;

			let lengthInSeconds = Math.round(trueLengthInSeconds);
			if (trueStartIsEarly) {
				// If real time is early, we have previously counted too much
				lengthInSeconds = Math.floor(trueLengthInSeconds);
			}
			else if (trueStartIsLate) {
				// If real time is late, we have previously counted too little
				lengthInSeconds = Math.ceil(trueLengthInSeconds);
			}

			secondsPassed += lengthInSeconds;

			phases.push({
				description,
				startAt: startAtSecond,
				duration: lengthInSeconds
			});
		}

		return phases;
	}
}
