import { MoonTimes, SunCalcFacade } from './SunCalcFacade';
import { MoonDay } from './MoonDay';
import { AstroPhase } from './AstroPhase';
import { AstroTimeEventSorter } from './AstroTimeEventSorter';
import { AstroTimeEvent } from './AstroTimeEvent';
import { IAstroDayFactory } from './IAstroDayFactory';
import { AstroPhaseFactory } from './AstroPhaseFactory';

export class MoonDayFactory implements IAstroDayFactory {
	private readonly sunCalcFacade: SunCalcFacade;

	private readonly astroTimeEventSorter: AstroTimeEventSorter;

	private readonly astroPhaseFactory: AstroPhaseFactory;

	constructor(sunCalcFacade: SunCalcFacade, astroTimeEventSorter: AstroTimeEventSorter, astroPhaseFactory: AstroPhaseFactory) {
		this.sunCalcFacade = sunCalcFacade;
		this.astroTimeEventSorter = astroTimeEventSorter;
		this.astroPhaseFactory = astroPhaseFactory;
	}

	public getDay(date: Date, latitude: number, longitude: number): MoonDay {
		const moonTimes = this.sunCalcFacade.getMoonTimes(date, latitude, longitude);
		const moonPhases = this.getMoonPhases(moonTimes);
		const moonDay = new MoonDay(date, moonPhases);

		return moonDay;
	}

	public getMoonPhases(moonTimes: MoonTimes): AstroPhase[] {
		if (moonTimes.alwaysUp) {
			return [{
				description: 'rise',
				startAt: 0,
				duration: 86_400
			}];
		}

		if (moonTimes.alwaysDown) {
			return [{
				description: 'set',
				startAt: 0,
				duration: 86_400
			}];
		}

		const moonTimesArray: AstroTimeEvent[] = [];
		if ('rise' in moonTimes) {
			moonTimesArray.push({
				date: moonTimes.rise,
				description: 'rise'
			});
		}
		if ('set' in moonTimes) {
			moonTimesArray.push({
				date: moonTimes.set,
				description: 'set'
			});
		}

		const sortedMoonTimes = this.astroTimeEventSorter.sortByDate(moonTimesArray);

		const phases = this.astroPhaseFactory.getPhases(sortedMoonTimes);

		return phases;
	}
}
