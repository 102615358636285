import { Container, Typography } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Page } from '../../components/Page';

export function NotFound(): JSX.Element {
	const location = useLocation();

	return (
		<Page title='Not found'>
			<Container>
				<Typography variant="h4" sx={{ pt: 10 }}>
					Path not found&nbsp;
					<code>{location.pathname}</code>
				</Typography>
			</Container>
		</Page>
	);
}
