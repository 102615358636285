import {
	Box, CircularProgress, List, ListItem, ListItemText, Typography
} from '@mui/material';
import React from 'react';

import { Page } from '../../../components/Page';
import { useAccountMeQuery, useAccountRoles } from '../Account.queries';

export function Start(): JSX.Element {
	const me = useAccountMeQuery();
	const roles = useAccountRoles();

	return (
		<Page title='Start - Account'>
			<Typography variant='h4' gutterBottom>
				Start
			</Typography>
			<Box
				sx={{
					p: 2,
					border: '1px dashed grey',
					borderRadius: '5px',
					mb: 2
				}}
			>
				<Typography variant='h6' gutterBottom>
					Account
				</Typography>
				{me.isLoading && <CircularProgress />}
				{me.data && (
					<Typography>
						<strong>Username</strong>
						&nbsp;
						{me.data.username}
					</Typography>
				)}
			</Box>
			<Box
				sx={{
					p: 2,
					border: '1px dashed grey',
					borderRadius: '5px'
				}}
			>
				<Typography variant='h6' gutterBottom>
					Roles
				</Typography>
				{roles.isLoading && <CircularProgress />}
				{roles.data && (
					<List>
						{roles.data.map(role => (
							<ListItem disableGutters key={role.id}>
								<ListItemText primary={role.name} />
							</ListItem>
						))}
						{(roles.data.length === 0) && (
							<ListItem disableGutters>
								<ListItemText primary='No roles assigned' />
							</ListItem>
						)}
					</List>
				)}
			</Box>
		</Page>
	);
}
