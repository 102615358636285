import { ApiError } from './ApiError';

export class ApiService {
	private static createServerUrl(url: string, parameters?: string | string[][] | Record<string, string> | URLSearchParams): string {
		if (parameters) {
			const queryParameters = new URLSearchParams(parameters).toString();
			return `${url}?${queryParameters.toString()}`;
		}

		return url;
	}

	private static createRequestOptions(method: string, headers: Record<string, string> = {}, data?: Record<string, unknown>): RequestInit {
		const options: RequestInit = {
			method,
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
				...headers
			}
		};

		if (data) {
			options.body = JSON.stringify(data);
		}

		return options;
	}

	public static async deleteRequest<T = unknown>(url: string, headers?: Record<string, string>): Promise<T> {
		const deleteUrl = this.createServerUrl(url);
		const deleteOptions = this.createRequestOptions('DELETE', headers);

		return this.doRequest<T>(deleteUrl, deleteOptions);
	}

	public static async getRequest<T = unknown>(url: string, parameters?: string | string[][] | Record<string, string> | URLSearchParams | undefined, headers?: Record<string, string>): Promise<T> {
		const getUrl = this.createServerUrl(url, parameters);
		const getOptions = this.createRequestOptions('GET', headers);

		return this.doRequest<T>(getUrl, getOptions);
	}

	public static async patchRequest<T = unknown>(url: string, data?: Record<string, unknown>, headers?: Record<string, string>): Promise<T> {
		const patchUrl = this.createServerUrl(url);
		const patchOptions = this.createRequestOptions('PATCH', headers, data);

		return this.doRequest<T>(patchUrl, patchOptions);
	}

	public static async postRequest<T = unknown>(url: string, data?: Record<string, unknown>, headers?: Record<string, string>): Promise<T> {
		const postUrl = this.createServerUrl(url);
		const postOptions = this.createRequestOptions('POST', headers, data);

		return this.doRequest<T>(postUrl, postOptions);
	}

	public static async putRequest<T = unknown>(url: string, data?: Record<string, unknown>, headers?: Record<string, string>): Promise<T> {
		const putUrl = this.createServerUrl(url);
		const putOptions = this.createRequestOptions('PUT', headers, data);

		return this.doRequest<T>(putUrl, putOptions);
	}

	static async doRequest<T>(url: string, options: RequestInit): Promise<T> {
		const apiResponse = await this.fetch(url, options);
		const contentType = apiResponse.headers.get('Content-Type');
		if (!(contentType?.includes('application/json'))) {
			throw new ApiError('Response was not JSON', -1);
		}

		const data = await this.validateJsonResponse(apiResponse);
		return data as T;
	}

	static async validateJsonResponse(response: Response): Promise<unknown> {
		let data;
		try {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			data = await response.json();
		}
		catch (error) {
			console.warn('Could not parse response as JSON', error);
			throw new ApiError(`HTTP/${response.status} for ${response.url}`, -1);
		}

		if ('errorCode' in data && 'message' in data) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
			throw new ApiError(data.message, data.errorCode);
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return data;
	}

	static async fetch(url: string, options: RequestInit): Promise<Response> {
		let apiResponse;
		try {
			apiResponse = await fetch(url, options);
		}
		catch (error) {
			throw new ApiError(`Unknown API error: ${(error as Error)?.message || 'Unknown error'}`, -1);
		}

		return apiResponse;
	}
}
