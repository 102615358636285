import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

type Properties = {
	children: JSX.Element;
};
export function RequireAuth({ children }: Properties): JSX.Element {
	const auth = useAuth();
	const location = useLocation();

	if (!auth.isAuthenticated) {
		return (
			<Navigate
				to="/auth/login"
				state={{ from: location }}
				replace
			/>
		);
	}

	return children;
}
