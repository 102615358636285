import jwtDecode, { JwtDecodeOptions, JwtPayload } from 'jwt-decode';
import { useEffect, useState } from 'react';

import { AuthEngine } from '../services/AuthEngine';

interface AccessTokenPayload extends JwtPayload {
	id: string;
	username: string;
	roles?: string[];
}

function useGetAccessToken() {
	const [accessToken] = useState<string | null>(AuthEngine.getAccessToken());

	return accessToken;
}

export function useAccessToken(options: JwtDecodeOptions = {}): AccessTokenPayload | undefined {
	const accessToken = useGetAccessToken();
	const [payload, setPayload] = useState<AccessTokenPayload | undefined>();

	useEffect(() => {
		if (accessToken) {
			const decoded = jwtDecode<AccessTokenPayload>(accessToken ?? '', options);
			console.log('useAccessToken', decoded);
			setPayload(decoded);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accessToken]);

	return payload;
}
