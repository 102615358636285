// eslint-disable-next-line node/no-unpublished-import,import/no-extraneous-dependencies
import { ReportHandler } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
	if (onPerfEntry) {
		// eslint-disable-next-line import/no-extraneous-dependencies,node/no-unsupported-features/es-syntax,node/no-unpublished-import,promise/always-return
		void import('web-vitals').then(({
			getCLS, getFID, getFCP, getLCP, getTTFB
		}) => {
			getCLS(onPerfEntry);
			getFID(onPerfEntry);
			getFCP(onPerfEntry);
			getLCP(onPerfEntry);
			getTTFB(onPerfEntry);
		});
	}
};

export default reportWebVitals;
