import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import {
	useMutation, useQueries, useQuery, useQueryClient
} from '@tanstack/react-query';

import { ApiError } from '../../services/ApiError';
import { CryptoSymbols } from './data/Symbols';
import { Wallet } from './data/Wallet';
import { AddWalletFormData, EditWalletFormData } from './data/WalletSchema';
import { BalanceService } from './services/BalanceService';
import { SymbolService } from './services/SymbolService';
import { WalletBalance, WalletService } from './services/WalletService';
import { ExchangeRateService, ExchangeRates } from './services/ExchangeRateService';

const queryKeys = createQueryKeyStore({
	walletWatchMeta: {
		assets: null,
		crypto: null,
		fiat: null,
		rates: (symbol: string) => [symbol]
	},
	walletWatchBalance: {
		supportedBlockchains: null,
		supportedPools: (symbol = '*') => [symbol],
		blockchain: ({ symbol, address }: { symbol: string; address: string }) => [`${symbol}-${address}`],
		pools: ({ symbol, address, pool = '*' }: { symbol: string; address: string; pool: string }) => [`${symbol}-${address}-${pool}`]
	},
	walletWatchWallets: {
		list: null,
		detail: (id: string) => ({
			queryKey: [id],
			contextQueries: {
				balance: null
			}
		})
	}
});

export const useSupportedPoolsQuery = (symbol?: string, enabled?: boolean) => useQuery<string[], ApiError>({
	queryKey: queryKeys.walletWatchBalance.supportedPools(symbol).queryKey,
	queryFn: () => BalanceService.getSupportedPools(symbol || ''),
	enabled
});

export const useRatesQuery = (symbol: string) => useQuery<ExchangeRates, ApiError>({
	queryKey: queryKeys.walletWatchMeta.rates(symbol).queryKey,
	queryFn: () => ExchangeRateService.getRates(symbol)
});

export const useCryptoSymbolsQuery = () => useQuery<CryptoSymbols, ApiError>({
	queryKey: queryKeys.walletWatchMeta.crypto.queryKey,
	queryFn: SymbolService.getCrypto
});

export const useWalletAddMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<void, ApiError, AddWalletFormData>({
		mutationFn: (data: AddWalletFormData) => WalletService.add(data),
		onSuccess: async () => {
			await queryClient.invalidateQueries(queryKeys.walletWatchWallets.list.queryKey);
		}
	});
};

type WalletEditMutation = {
	walletId: string;
	data: EditWalletFormData;
};

export const useWalletEditMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<void, ApiError, WalletEditMutation>({
		mutationFn: ({ walletId, data }: WalletEditMutation) => WalletService.edit(walletId, data),
		onSuccess: async (result, mutation) => {
			await queryClient.invalidateQueries(queryKeys.walletWatchWallets.list.queryKey);
			await queryClient.invalidateQueries(queryKeys.walletWatchWallets.detail(mutation.walletId).queryKey);
		}
	});
};

export const useWalletRemoveMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<void, ApiError, string>({
		mutationFn: (walletId: string) => WalletService.remove(walletId ?? ''),
		onSuccess: async (result, walletId) => {
			await queryClient.invalidateQueries(queryKeys.walletWatchWallets.list.queryKey);
			await queryClient.invalidateQueries({
				queryKey: queryKeys.walletWatchWallets.detail(walletId).queryKey,
				refetchType: 'none'
			});
		}
	});
};

export const useWalletsQuery = () => useQuery<Wallet[], ApiError>({
	queryKey: queryKeys.walletWatchWallets.list.queryKey,
	queryFn: WalletService.list
});

export const useWalletQuery = (walletId: string, enabled?: boolean) => useQuery<Wallet, ApiError>({
	queryKey: queryKeys.walletWatchWallets.detail(walletId).queryKey,
	queryFn: () => WalletService.get(walletId),
	enabled
});

export const useWalletBalanceQuery = (walletId: string) => useQuery<WalletBalance, ApiError>({
	// eslint-disable-next-line no-underscore-dangle
	queryKey: queryKeys.walletWatchWallets.detail(walletId)._ctx.balance.queryKey,
	queryFn: () => WalletService.getBalance(walletId)
});

export const useWalletsBalancesQueries = (walletIds: string[]) => useQueries<WalletBalance[]>({
	queries: walletIds.map(walletId => ({
		// eslint-disable-next-line no-underscore-dangle
		queryKey: queryKeys.walletWatchWallets.detail(walletId)._ctx.balance.queryKey,
		queryFn: () => WalletService.getBalance(walletId)
	}))
});
