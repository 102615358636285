import React from 'react';
import {
	Paper, Table, TableBody, TableContainer, Typography
} from '@mui/material';

import { LoadingPlaceholder } from '../../../components/LoadingPlaceholder';
import { Page } from '../../../components/Page';
import { ApiErrorAlert } from '../../Auth/components/ApiErrorAlert';
import { ManageHabitsListRow } from '../components/ManageHabitsListRow';
import { useHabitsQuery } from '../Tracker.queries';

export function TrackHabits(): JSX.Element {
	const habits = useHabitsQuery();

	return (
		<Page title='Track Habits - Tracker'>
			<Typography
				variant='h6'
				sx={{ mb: 2 }}
			>
				Track Habits
			</Typography>
			<TableContainer component={Paper} elevation={0}>
				<Table>
					<TableBody>
						{habits.data && habits.data.filter(habit => habit.directEntry).map(habit => <ManageHabitsListRow key={habit.id} habit={habit} habits={habits.data} />)}
					</TableBody>
				</Table>
				<LoadingPlaceholder
					isLoading={habits.isLoading}
					label='Loading Habits...'
				/>
				{habits.error && (
					<ApiErrorAlert apiError={habits.error} sx={{ m: 2 }} />
				)}
			</TableContainer>
		</Page>
	);
}
