import React, { ReactNode } from 'react';
import {
	Box, BoxProps, Divider, Drawer, DrawerProps, IconButton, Typography, useTheme
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { DrawerHeader } from '../components/DrawerHeader';

interface ResponsiveDrawerProperties {
	width: number;
	title?: string;
	open: boolean;
	onClose: DrawerProps['onClose'];
	children: ReactNode;
}

PersistentDrawer.defaultProps = {
	title: undefined
};

export function PersistentDrawer({
	width, title, open, onClose, children
}: ResponsiveDrawerProperties & BoxProps): JSX.Element {
	const theme = useTheme();

	// TODO: Temp while signature is bad
	const handleDrawerClose = () => {
		if (onClose) {
			onClose({}, 'backdropClick');
		}
	};

	return (
		<Drawer
			sx={{
				width,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width,
					boxSizing: 'border-box'
				}
			}}
			variant='persistent'
			anchor='left'
			open={open}
		>
			<DrawerHeader>
				{title && (
					<Typography
						variant='h6'
						noWrap
					>
						{title}
					</Typography>
				)}
				<Box sx={{ flexGrow: 1 }} />
				<IconButton
					onClick={handleDrawerClose}
					aria-label='Close drawer'
				>
					{theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
				</IconButton>
			</DrawerHeader>
			<Divider />
			{children}
		</Drawer>
	);
}
