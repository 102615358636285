import { LocalStorage } from './LocalStorage';
import { TokenData } from './TokenData';

export class AuthEngine {
	public static setTokenData(tokenData: TokenData): void {
		LocalStorage.set('accessToken', tokenData.accessToken);
		LocalStorage.set('refreshTokenExpiresAt', tokenData.refreshTokenExpiresAt);
	}

	public static clearTokenData(): void {
		LocalStorage.remove('accessToken');
		LocalStorage.remove('refreshTokenExpiresAt');
	}

	public static getRefreshTokenExpiresAt(): string | null {
		return LocalStorage.get('refreshTokenExpiresAt');
	}

	public static getAccessToken(): string | null {
		return LocalStorage.get('accessToken');
	}

	public static isAuthenticated(): boolean {
		const refreshTokenExpiresAt = this.getRefreshTokenExpiresAt();
		if (refreshTokenExpiresAt === null) {
			return false;
		}

		const expiresAt = new Date(refreshTokenExpiresAt).getTime();
		const isExpired = expiresAt < Date.now();
		return !isExpired;
	}
}
