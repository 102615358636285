export class LocalStorage {
	public static set(key: string, value: string): void {
		localStorage.setItem(key, value);
	}

	public static get(key: string): string | null {
		const value = localStorage.getItem(key);
		return value;
	}

	public static remove(key: string): void {
		localStorage.removeItem(key);
	}
}
