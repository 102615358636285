import {
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { LoadingPlaceholder } from '../../../components/LoadingPlaceholder';
import { Page } from '../../../components/Page';
import { ApiErrorAlert } from '../../Auth/components/ApiErrorAlert';
import { MediaRequestListRow } from '../components/MediaRequestListRow';
import {
	useMediaRequestListQuery,
	useMediaRequestRemoveMutation,
	useMediaRequestSetStateMutation
} from '../MediaRequest.queries';
import { MediaRequestState } from '../data/MediaRequestState';

export function ListMediaRequests(): JSX.Element {
	const mediaRequestsQuery = useMediaRequestListQuery();
	const setState = useMediaRequestSetStateMutation();
	const remove = useMediaRequestRemoveMutation();

	const onStateChange = (id: string, state: MediaRequestState) => {
		setState.mutate({
			id,
			state
		});
	};

	const onRemove = async (id: string) => {
		await remove.mutateAsync(id);
	};

	return (
		<Page title='Media Request'>
			<Button
				variant='contained'
				component={Link}
				to='/media-request/add'
				sx={{ mb: 2 }}
			>
				Add new Media Request
			</Button>
			<TableContainer component={Paper} elevation={0}>
				<Table aria-label='Media Requests' size='small'>
					<TableHead>
						<TableRow>
							<TableCell>Name and year</TableCell>
							<TableCell />
							<TableCell align='center'>URL</TableCell>
							<TableCell align='center'>Type</TableCell>
							<TableCell align='center'>Action</TableCell>
							<TableCell align='center' />
							<TableCell align='center'>Requested by</TableCell>
							<TableCell align='center'>State</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{mediaRequestsQuery.data && mediaRequestsQuery.data.map(request => (
							<MediaRequestListRow
								key={request.id}
								request={request}
								onStateChange={onStateChange}
								onRemove={onRemove}
							/>
						))}
					</TableBody>
				</Table>
				<LoadingPlaceholder
					isLoading={mediaRequestsQuery.isLoading}
					label='Loading Media Requests...'
				/>
				{mediaRequestsQuery.error && (
					<ApiErrorAlert apiError={mediaRequestsQuery.error} sx={{ m: 2 }} />
				)}
				{setState.error && (
					<ApiErrorAlert apiError={setState.error} sx={{ m: 2 }} />
				)}
				{remove.error && (
					<ApiErrorAlert apiError={remove.error} sx={{ m: 2 }} />
				)}
			</TableContainer>
		</Page>
	);
}
