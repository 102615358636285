import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface Properties {
	to: string;
}

export function EditIconButtonLink({ to }: Properties): JSX.Element {
	return (
		<IconButton
			aria-label='edit'
			size='small'
			component={Link}
			to={to}
			sx={{
				textTransform: 'none',
				color: 'inherit'
			}}
		>
			<Edit fontSize='small' />
		</IconButton>
	);
}
