import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import React, { useState } from 'react';

import { InputProperties } from './InputProperties';
import { InputTextField } from './InputTextField';

export function InputPasswordFieldWithToggle({
	name, control, ...others
}: InputProperties & TextFieldProps): JSX.Element {
	const [showPassword, setShowPassword] = useState(false);

	const handleShowPassword = () => {
		setShowPassword(show => !show);
	};

	return (
		<InputTextField
			name={name}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			control={control}
			type={showPassword ? 'text' : 'password'}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton onClick={handleShowPassword} edge="end">
							{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
						</IconButton>
					</InputAdornment>
				)
			}}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...others}
		/>
	);
}
