import {
	Divider,
	List
} from '@mui/material';
import { BugReport, Nightlight, WbSunny } from '@mui/icons-material';
import React from 'react';
import useGeolocation from 'react-hook-geolocation';
import { Route, Routes } from 'react-router-dom';

import { ListItemLink } from '../../components/ListItemLink';
import { PersistentDrawerShell } from '../../layout/PersistentDrawerShell/PersistentDrawerShell';
import { Debug } from './views/Debug';
import { SunMoonLight } from './views/SunMoonLight';
import { NotFound } from '../NotFound/NotFound';

export function SunnyShine(): JSX.Element {
	const geolocation = useGeolocation();

	const menu = (
		<>
			<List>
				<ListItemLink
					icon={<WbSunny />}
					primary='Sun Day'
					to='/sunny-shine/sun/day'
				/>
				<ListItemLink
					icon={<WbSunny />}
					primary='Sun Month'
					to='/sunny-shine/sun/month'
				/>
				<ListItemLink
					icon={<WbSunny />}
					primary='Sun Quarter'
					to='/sunny-shine/sun/quarter'
				/>
				<ListItemLink
					icon={<WbSunny />}
					primary='Sun Year'
					to='/sunny-shine/sun/year'
				/>
			</List>
			<Divider />
			<List>
				<ListItemLink
					icon={<Nightlight />}
					primary='Moon Day'
					to='/sunny-shine/moon/day'
				/>
				<ListItemLink
					icon={<Nightlight />}
					primary='Moon Month'
					to='/sunny-shine/moon/month'
				/>
				<ListItemLink
					icon={<Nightlight />}
					primary='Moon Quarter'
					to='/sunny-shine/moon/quarter'
				/>
				<ListItemLink
					icon={<Nightlight />}
					primary='Moon Year'
					to='/sunny-shine/moon/year'
				/>
			</List>
			<Divider />
			<List>
				<ListItemLink
					icon={<BugReport />}
					primary='Debug'
					to='/sunny-shine/debug'
				/>
			</List>
		</>
	);

	return (
		<Routes>
			<Route
				path='/*'
				element={(
					<PersistentDrawerShell
						menuItems={menu}
						title='Sunny Shine'
					/>
				)}
			>
				<Route
					path='sun/:period'
					element={(
						<SunMoonLight
							mode='sun'
							geolocation={geolocation}
						/>
					)}
				/>
				<Route
					path='moon/:period'
					element={(
						<SunMoonLight
							mode='moon'
							geolocation={geolocation}
						/>
					)}
				/>
				<Route path='debug' element={<Debug />} />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
}
