import {
	AppBar, Box, Container, Paper, Toolbar
} from '@mui/material';
import { Breakpoint } from '@mui/system/createTheme/createBreakpoints';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from '../components/Header';

interface ShellProperties {
	title?: string;
	fixed?: boolean;
	maxWidth?: Breakpoint | false;
}

Shell.defaultProps = {
	title: undefined,
	fixed: false,
	maxWidth: false
};

export function Shell({
	title,
	fixed,
	maxWidth
}: ShellProperties): JSX.Element {
	return (
		<Paper square sx={{ display: 'flex', minHeight: '100vh' }}>
			<AppBar position='fixed'>
				<Toolbar>
					<Header title={title} />
				</Toolbar>
			</AppBar>
			<Box
				component='main'
				sx={{ flexGrow: 1, p: 3 }}
			>
				<Toolbar />
				<Container fixed={fixed || false} maxWidth={maxWidth || false}>
					<Outlet />
				</Container>
			</Box>
		</Paper>
	);
}
