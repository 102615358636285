import * as yup from 'yup';

export const HabitSchema = yup.object({
	name: yup.string().required('Name is required'),
	directEntry: yup.boolean().required(),
	ordering: yup.number(),
	requireCount: yup.boolean().required(),
	options: yup.array().of(yup.string().required('Can\'t be empty. Makes no sense.')),
	composedOf: yup.array().of(yup.object({
		id: yup.string().required('ID required for composition relation'),
		name: yup.string().required('Name required for composition relation')
	}))
});

export type AddEditHabitFormData = yup.InferType<typeof HabitSchema>;
