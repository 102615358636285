import { Alert, AlertProps, Typography } from '@mui/material';
import React from 'react';

import { ApiError } from '../../../services/ApiError';

type Properties = {
	apiError: ApiError;
};

export function ApiErrorAlert({ apiError, sx }: Properties & AlertProps): JSX.Element {
	return (
		<Alert variant='outlined' severity='error' sx={sx}>
			<Typography>
				{apiError.message}
			</Typography>
			<Typography variant="caption">
				Error Code&nbsp;
				{apiError.errorCode}
			</Typography>
		</Alert>
	);
}
