import React from 'react';
import { Theaters, Tv } from '@mui/icons-material';

import { ColorIconChipData } from '../components/ColorIconChip';
import { MediaRequestType } from './MediaRequestType';

export const TypeChips: Record<MediaRequestType, ColorIconChipData> = {
	MOVIE: {
		label: 'Movie',
		icon: <Theaters />,
		color: 'primary'
	},
	SERIES: {
		label: 'Series',
		icon: <Tv />,
		color: 'secondary'
	}
};
