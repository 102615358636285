import { Insights } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';

import { Page } from '../../../components/Page';

export function Analyse(): JSX.Element {
	return (
		<Page title='Analyse - Tracker'>
			<Typography variant='h6'>
				Analyse
			</Typography>
			<Insights sx={{ fontSize: 200 }} />
		</Page>
	);
}
