import React from 'react';
import { Link } from 'react-router-dom';
import {
	Card, CardActionArea, CardContent, Container, Grid, Typography
} from '@mui/material';

import { useAccessToken } from '../../hooks/useAccessToken';
import { useAuth } from '../../hooks/useAuth';
import { getAppRoutesByAuthState } from '../../app-routes';

export function AppSelector(): JSX.Element | null {
	const auth = useAuth();
	const roles = useAccessToken()?.roles ?? [];

	const appRoutes = getAppRoutesByAuthState(auth.isAuthenticated, roles);
	if (appRoutes.length === 0) {
		return null;
	}

	return (
		<Container fixed maxWidth='xs'>
			<Grid
				container
				spacing={2}
			>
				{appRoutes.map(route => (
					<Grid item xs={4} key={route.to}>
						<Card elevation={0}>
							<CardActionArea
								component={Link}
								to={route.to}
							>
								<CardContent>
									<Typography
										align='center'
									>
										{route.icon}
									</Typography>

									<Typography
										align='center'
									>
										{route.title}
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>
				))}
			</Grid>
		</Container>
	);
}
