import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	Navigate, useLocation, useNavigate
} from 'react-router-dom';
import * as yup from 'yup';

import { InputCheckbox } from '../../../components/form/InputCheckbox';
import { InputTextField } from '../../../components/form/InputTextField';
import { InputPasswordFieldWithToggle } from '../../../components/form/InputPasswordFieldWithToggle';
import { useAuth } from '../../../hooks/useAuth';
import { ApiError } from '../../../services/ApiError';
import { LocationState } from '../../../services/LocationState';
import { ApiErrorAlert } from '../components/ApiErrorAlert';

const LoginSchema = yup.object({
	username: yup.string()
		.required('Username is required'),
	password: yup.string()
		.required('Password is required'),
	remember: yup.boolean()
});

type LoginFormData = yup.InferType<typeof LoginSchema>;

export function LoginForm(): JSX.Element {
	const auth = useAuth();
	const location = useLocation();
	const navigate = useNavigate();
	const [apiError, setApiError] = useState<ApiError | null>();

	const previousState = location.state ? location.state as LocationState : undefined;
	const destinationPath = previousState?.from?.pathname ?? '/';
	if (destinationPath === '/') {
		console.log('Will redirect to default', destinationPath);
	}
	else {
		console.log('Will redirect back to', destinationPath);
	}

	const {
		handleSubmit, control, formState: {
			errors, isDirty, isValid, isSubmitting
		}
	} = useForm<LoginFormData>({
		mode: 'all',
		resolver: yupResolver(LoginSchema)
	});

	if (Object.keys(errors).length > 0) {
		console.log(`Validation errors: ${JSON.stringify(errors, null, 4)}`);
	}

	const onSubmit = async (data: LoginFormData) => {
		setApiError(null);
		try {
			await auth.login(data.username, data.password, data.remember || false);
			navigate(destinationPath, { replace: true });
		}
		catch (error) {
			if (error instanceof ApiError) {
				setApiError(error);
			}
			else {
				console.error('Login error', error);
			}
		}
	};

	return auth.isAuthenticated ? (
		<Navigate
			to={destinationPath}
			replace
		/>
	) : (
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={3}>
				<InputTextField
					name='username'
					control={control}
					fullWidth
					autoFocus
					autoComplete='username'
					label='Username'
				/>
				<InputPasswordFieldWithToggle
					name='password'
					control={control}
					fullWidth
					label='Password'
				/>
				<Stack
					direction='row'
					alignItems='center'
					justifyContent='space-between'
					sx={{ my: 2 }}
				>
					<InputCheckbox
						name='remember'
						control={control}
						label='Remember me'
					/>
				</Stack>

				{apiError && (
					<ApiErrorAlert apiError={apiError} />
				)}

				<LoadingButton
					fullWidth
					size='large'
					type='submit'
					variant='contained'
					disabled={!isDirty || !isValid}
					loading={isSubmitting}
				>
					Login
				</LoadingButton>
			</Stack>
		</form>
	);
}
