import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { List } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Add, Insights } from '@mui/icons-material';

import { ListItemLink } from '../../components/ListItemLink';
import { ResponseDrawerShell } from '../../layout/ResponsiveDrawerShell/ResponsiveDrawerShell';
import { NotFound } from '../NotFound/NotFound';
import { AddHabit } from './views/AddHabit';
import { Analyse } from './views/Analyse';
import { EditHabit } from './views/EditHabit';
import { ManageHabits } from './views/ManageHabits';
import { TrackHabits } from './views/TrackHabits';

export function Tracker(): JSX.Element {
	const menu = (
		<List>
			<ListItemLink
				icon={<Add />}
				primary='Track habits'
				to='/tracker/track'
			/>
			<ListItemLink
				icon={<Insights />}
				primary='Analyse habits'
				to='/tracker/analyse'
			/>
			<ListItemLink
				icon={<FormatListBulletedIcon />}
				primary='Manage habits'
				to='/tracker/habits'
			/>
		</List>
	);

	return (
		<Routes>
			<Route
				path='/*'
				element={(
					<ResponseDrawerShell
						menuItems={menu}
						title='Habit Tracker'
					/>
				)}
			>
				<Route index element={<ManageHabits />} />
				<Route path='analyse' element={<Analyse />} />
				<Route path='habits'>
					<Route index element={<ManageHabits />} />
					<Route path='add' element={<AddHabit />} />
					<Route path='edit/:id' element={<EditHabit />} />
					<Route path='manage' element={<ManageHabits />} />
				</Route>
				<Route path='track' element={<TrackHabits />} />
				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
}
