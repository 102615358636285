import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
	Button, Paper, Stack, Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { InputAutocompleteMultiple } from '../../../components/form/InputAutocompleteMultiple';
import { InputCheckbox } from '../../../components/form/InputCheckbox';
import { InputCustomList } from '../../../components/form/InputCustomList';
import { InputTextField } from '../../../components/form/InputTextField';
import { LoadingPlaceholder } from '../../../components/LoadingPlaceholder';
import { Page } from '../../../components/Page';
import { ApiErrorAlert } from '../../Auth/components/ApiErrorAlert';
import { Habit } from '../data/Habit';
import { AddEditHabitFormData, HabitSchema } from '../data/HabitSchema';
import { useHabitAddMutation, useHabitComposeOptionsQuery } from '../Tracker.queries';

const defaultValues: Required<Omit<AddEditHabitFormData, 'ordering'>> & { ordering?: number } = {
	name: '',
	directEntry: true,
	ordering: undefined,
	requireCount: false,
	options: [],
	composedOf: []
};

export function AddHabit(): JSX.Element {
	const navigate = useNavigate();
	const [options, setOptions] = useState<string[]>([]);
	const [selectedHabitComposeOptions, setSelectedHabitComposeOptions] = useState<Habit[]>([]);

	const {
		handleSubmit, control, reset, setValue, formState: {
			errors, isDirty, isValid, isSubmitting
		}
	} = useForm<AddEditHabitFormData>({
		defaultValues,
		mode: 'all',
		resolver: yupResolver(HabitSchema)
	});

	if (Object.keys(errors).length > 0) {
		console.log(`Validation errors: ${JSON.stringify(errors, null, 4)}`);
	}

	const composeOptions = useHabitComposeOptionsQuery();

	const add = useHabitAddMutation();

	const onReset = () => {
		setOptions([]);
		setSelectedHabitComposeOptions([]);
		reset();
	};

	const onValid = async (data: AddEditHabitFormData) => {
		await add.mutateAsync(data, {
			onSuccess: () => navigate('/tracker/habits/manage')
		});
	};

	return (
		<Page title='Add Habit - Tracker'>
			{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
			<form onSubmit={handleSubmit(onValid)}>
				<Stack spacing={3}>
					<Typography variant='h6'>
						Add habit to track
					</Typography>
					<InputTextField
						name='name'
						control={control}
						label='Name'
					/>
					<InputTextField
						name='ordering'
						control={control}
						label='Ordering'
						type='number'
						inputProps={{
							inputMode: 'numeric',
							pattern: '[0-9]*'
						}}
					/>
					<InputCheckbox
						name='directEntry'
						control={control}
						label='Allow direct tracking'
					/>
					<InputCheckbox
						name='requireCount'
						control={control}
						label='Require count to be set when tracking'
					/>
					<Paper
						variant='outlined'
						sx={{
							p: 2
						}}
					>
						<InputCustomList
							name='options'
							label='Options - Require one when tracking'
							setValue={setValue}
							items={options}
							setItems={setOptions}
						/>
					</Paper>

					{composeOptions.isLoading ? (
						<LoadingPlaceholder
							isLoading={composeOptions.isLoading}
							label='Loading habit compose options'
						/>
					) : (
						<InputAutocompleteMultiple<Habit>
							name='composedOf'
							label='Composed of'
							setValue={setValue}
							options={composeOptions.data ?? []}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							getOptionLabel={option => option.name}
							selectedOptions={selectedHabitComposeOptions}
							setSelectedOptions={setSelectedHabitComposeOptions}
						/>
					)}
					{composeOptions.error && (
						<ApiErrorAlert apiError={composeOptions.error} />
					)}
					{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
					<LoadingButton
						size='large'
						type='submit'
						variant='contained'
						disabled={!isDirty || !isValid}
						loading={isSubmitting}
					>
						Add
					</LoadingButton>
					{add.error && (
						<ApiErrorAlert apiError={add.error} />
					)}
					<Button
						disabled={!isDirty}
						onClick={onReset}
						variant='outlined'
					>
						Reset
					</Button>
				</Stack>
			</form>
		</Page>
	);
}
