import {
	Button, FormControl, FormGroup, FormLabel, IconButton, InputAdornment, Stack, TextField
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import React, {
	Dispatch, SetStateAction, useEffect, useState
} from 'react';

import { InputProperties } from './InputProperties';

interface InputCustomListProperties {
	label: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setValue: any;
	items: string[];
	setItems: Dispatch<SetStateAction<string[]>>;
}

export function InputCustomList({
	name,
	label,
	setValue,
	items,
	setItems
}: InputCustomListProperties & Omit<InputProperties, 'control'>): JSX.Element {
	const [enableAddEntry, setEnableAddEntry] = useState(false);

	const handleChange = (index: number, value: string) => {
		if (value && index < items.length) {
			const theItems = [...items];
			theItems[index] = value;
			setItems(theItems);
		}
	};

	const handleDelete = (index: number) => {
		if (index < items.length) {
			setItems([...items.slice(0, index), ...items.slice(index + 1)]);
		}
		else {
			console.warn(`Index ${index} out of bounds`);
		}
	};

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		setValue(name, items, {
			shouldValidate: true,
			shouldDirty: true
		});
	}, [name, items, setValue]);

	useEffect(() => {
		if (items.length === 0) {
			setEnableAddEntry(true);
		}
		else if (items.filter(item => item.length).length === items.length) {
			setEnableAddEntry(true);
		}
		else {
			setEnableAddEntry(false);
		}
	}, [items]);

	return (
		<FormControl
			variant='outlined'
			component='fieldset'
			sx={{
				width: '100%'
			}}
		>
			<FormGroup>
				<Stack spacing={2}>
					<FormLabel component='legend'>{label}</FormLabel>
					{items.map((item, index) => (
						<TextField
							onBlur={event => handleChange(index, event.target.value)}
							onChange={event => handleChange(index, event.target.value)}
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
							value={item}
							variant='outlined'
							placeholder='Add option'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<IconButton onClick={() => handleDelete(index)} edge='end'>
											<Delete />
										</IconButton>
									</InputAdornment>
								)
							}}
						/>
					))}
					<Button
						fullWidth
						size='large'
						variant='contained'
						disabled={!enableAddEntry}
						onClick={() => setItems([...items, ''])}
					>
						Add entry
					</Button>
				</Stack>
			</FormGroup>
		</FormControl>
	);
}
