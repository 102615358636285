import { useMediaQuery, useTheme } from '@mui/material';
import { Breakpoint } from '@mui/system/createTheme';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

function MHidden({ width, children }: InferProps<typeof MHidden.propTypes>): JSX.Element | null {
	const breakpoint = width.slice(0, 2) as Breakpoint;

	const theme = useTheme();
	const hiddenUp = useMediaQuery(theme.breakpoints.up(breakpoint));
	const hiddenDown = useMediaQuery(theme.breakpoints.down(breakpoint));

	const downButNotHiddenDown = width.includes('Down') && !hiddenDown;
	const upButNotHiddenUp = width.includes('Up') && !hiddenUp;

	if (downButNotHiddenDown || upButNotHiddenUp) {
		return (
			<div>
				{children}
			</div>
		);
	}

	return null;
}

MHidden.propTypes = {
	children: PropTypes.node.isRequired,
	width: PropTypes.oneOf([
		'xsDown',
		'smDown',
		'mdDown',
		'lgDown',
		'xlDown',
		'xsUp',
		'smUp',
		'mdUp',
		'lgUp',
		'xlUp'
	]).isRequired
};

export default MHidden;
