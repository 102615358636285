import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';

import { useAccessToken } from '../../hooks/useAccessToken';
import { AccountService } from './services/AccountService';

const queryKeys = createQueryKeys('account', {
	me: null,
	roles: null
});

export const useAccountMeQuery = () => useQuery({
	queryKey: queryKeys.me.queryKey,
	queryFn: AccountService.getMe
});

export const useAccountRoles = () => {
	const accessToken = useAccessToken();

	return useQuery({
		queryKey: queryKeys.roles.queryKey,
		queryFn: () => AccountService.getRoles().then(roles => roles.filter(role => accessToken?.roles?.includes(role.id))),
		enabled: !!accessToken
	});
};
