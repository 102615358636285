import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiError } from '../../services/ApiError';
import { MediaRequestAction } from './data/MediaRequestAction';
import { AddMediaRequestsFormData } from './data/MediaRequestSchema';
import { MediaRequestType } from './data/MediaRequestType';
import { MediaRequest } from './data/MediaRequest';
import { MediaRequestService } from './services/MediaRequestService';
import { MediaRequestState } from './data/MediaRequestState';
import { mediaRequestSorter } from './data/mediaRequestSorter';

const queryKeys = createQueryKeys('mediaRequest', {
	list: null
});

export const useMediaRequestAddMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<void, ApiError, AddMediaRequestsFormData>({
		mutationFn: (data: AddMediaRequestsFormData) => MediaRequestService.add({
			name: data.name,
			year: Number.parseInt(data.year, 10),
			url: data.url,
			type: data.type as MediaRequestType,
			action: data.action as MediaRequestAction,
			expedite: data.expedite,
			comment: data.comment
		}),
		onSuccess: async () => {
			await queryClient.invalidateQueries(queryKeys.list.queryKey);
		}
	});
};

type SetStateVariables = {
	id: string;
	state: MediaRequestState;
};

type SetStateContext = {
	previousMediaRequests: MediaRequest[] | undefined;
};

export const useMediaRequestSetStateMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<void, ApiError, SetStateVariables, SetStateContext>({
		mutationFn: ({ id, state }: SetStateVariables) => MediaRequestService.setState(id, state),
		onMutate: async variables => {
			// Cancel any outgoing re-fetches (so they don't overwrite our optimistic update)
			await queryClient.cancelQueries(queryKeys.list.queryKey);

			const previousMediaRequests = queryClient.getQueryData<MediaRequest[]>(queryKeys.list.queryKey);
			if (previousMediaRequests) {
				// Optimistic update
				queryClient.setQueryData<MediaRequest[]>(queryKeys.list.queryKey, previous => {
					const newData = [...previous?.map(item => ({ ...item })) ?? []];
					const mediaRequest = newData.find(mediaRequest => mediaRequest.id === variables.id);
					if (mediaRequest) {
						mediaRequest.state = variables.state;
					}

					newData.sort(mediaRequestSorter);

					return newData;
				});
			}

			return { previousMediaRequests };
		},
		onError: (error, variables, context) => {
			if (context?.previousMediaRequests) {
				queryClient.setQueryData<MediaRequest[]>(queryKeys.list.queryKey, context.previousMediaRequests);
			}
		},
		onSettled: () => {
			void queryClient.invalidateQueries(queryKeys.list.queryKey);
		}
	});
};

export const useMediaRequestRemoveMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<void, ApiError, string>({
		mutationFn: (id: string) => MediaRequestService.remove(id),
		onSuccess: async (_data, id) => {
			queryClient.setQueryData<MediaRequest[]>(queryKeys.list.queryKey, previous => (previous ? previous.filter(mediaRequest => mediaRequest.id !== id) : previous));
		}
	});
};

export const useMediaRequestListQuery = () => useQuery<MediaRequest[], ApiError>({
	queryKey: queryKeys.list.queryKey,
	queryFn: MediaRequestService.list,
	onSuccess: data => data.sort(mediaRequestSorter)
});
