import * as yup from 'yup';

export const MediaRequestSchema = yup.object({
	name: yup.string().required('Name is required'),
	year: yup.string().required('Year is required').matches(/^\d{4}$/, 'Year must be of the format YYYY; e.g. 2022'),
	url: yup.string().required('Link to media is required for reference').matches(/^https:\/\//, 'URL must start with https://'),
	type: yup.string().required('Type is required'), // Can I regex match with options from enum?
	action: yup.string().required('Action is required'), // Can I regex match with options from enum?
	expedite: yup.boolean(),
	comment: yup.string()
});

export type AddMediaRequestsFormData = yup.InferType<typeof MediaRequestSchema>;
