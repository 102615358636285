import { Box } from '@mui/material';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

export function Logo({ sx }: InferProps<typeof Logo.propTypes>): JSX.Element {
	return (
		<Box
			component="img"
			src="/logo512.png"
			alt="Proto Suite"
			sx={{ width: 40, height: 40, ...sx }}
		/>
	);
}

Logo.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	sx: PropTypes.object
};

Logo.defaultProps = {
	sx: {}
};
