import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiError } from '../../services/ApiError';
import { AddEditHabitFormData } from './data/HabitSchema';
import { Habit } from './data/Habit';
import { habitSorter } from './data/habitSorter';
import { HabitService } from './HabitService';

const queryKeys = createQueryKeyStore({
	trackerHabits: {
		list: null,
		detail: (id: string) => [id],
		composeOptions: (id = '*') => [id]
	}
});

export const useHabitAddMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<void, ApiError, AddEditHabitFormData>({
		mutationFn: (data: AddEditHabitFormData) => HabitService.add({
			name: data.name,
			directEntry: data.directEntry,
			ordering: data.ordering,
			requireCount: data.requireCount,
			options: data.options,
			composedOf: data.composedOf?.map(option => option.id)
		}),
		onSuccess: async () => {
			await queryClient.invalidateQueries(queryKeys.trackerHabits.list.queryKey);
		}
	});
};

type HabitEditMutation = {
	habitId: string;
	data: AddEditHabitFormData;
};

export const useHabitEditMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<void, ApiError, HabitEditMutation>({
		mutationFn: ({ habitId, data }: HabitEditMutation) => HabitService.edit(habitId, {
			name: data.name,
			directEntry: data.directEntry,
			ordering: data.ordering,
			requireCount: data.requireCount,
			options: data.options,
			composedOf: data.composedOf?.map(option => option.id) ?? []
		}),
		onSuccess: async (result, mutation) => {
			await queryClient.invalidateQueries(queryKeys.trackerHabits.list.queryKey);
			await queryClient.invalidateQueries(queryKeys.trackerHabits.detail(mutation.habitId).queryKey);
		}
	});
};

export const useHabitRemoveMutation = () => {
	const queryClient = useQueryClient();

	return useMutation<void, ApiError, string>({
		mutationFn: (habitId: string) => HabitService.remove(habitId),
		onSuccess: async (result, habitId) => {
			queryClient.setQueryData<Habit[]>(queryKeys.trackerHabits.list.queryKey, previous => (previous ? previous.filter(habit => habit.id !== habitId) : previous));
			await queryClient.invalidateQueries({
				queryKey: queryKeys.trackerHabits.detail(habitId).queryKey,
				refetchType: 'none'
			});
		}
	});
};

export const useHabitQuery = (habitId: string, enabled?: boolean) => useQuery<Habit, ApiError>({
	queryKey: queryKeys.trackerHabits.detail(habitId).queryKey,
	queryFn: () => HabitService.get(habitId),
	enabled
});

export const useHabitsQuery = () => useQuery<Habit[], ApiError>({
	queryKey: queryKeys.trackerHabits.list.queryKey,
	queryFn: HabitService.list,
	onSuccess: data => data.sort(habitSorter)
});

export const useHabitComposeOptionsQuery = (habitId?: string, enabled?: boolean) => useQuery<Habit[], ApiError>({
	queryKey: queryKeys.trackerHabits.composeOptions(habitId).queryKey,
	queryFn: () => HabitService.getComposeOptions(habitId),
	onSuccess: data => data.sort(habitSorter),
	enabled
});
