import { AuthedApiService } from '../../../services/AuthedApiService';
import { MediaRequestState } from '../data/MediaRequestState';
import { MediaRequest } from '../data/MediaRequest';

type MediaRequestAddEditProperties = Omit<MediaRequest, 'id' | 'requestedById' | 'requestedByUsername' | 'state'>;

export class MediaRequestService {
	static async add(properties: MediaRequestAddEditProperties): Promise<void> {
		await AuthedApiService.post('/media-request', properties);
	}

	static async remove(id: string): Promise<void> {
		await AuthedApiService.delete(`/media-request/${id}`);
	}

	static list(): Promise<MediaRequest[]> {
		return AuthedApiService.get<MediaRequest[]>('/media-request');
	}

	static setState(id: string, state: MediaRequestState): Promise<void> {
		return AuthedApiService.post(`/media-request/${id}/set-state`, {
			state
		});
	}
}
