import {
	IconButton,
	Link,
	TableCell,
	TableRow,
	Tooltip
} from '@mui/material';
import {
	Chat,
	Delete,
	FastForward,
	OpenInNew
} from '@mui/icons-material';
import React, { useMemo, useState } from 'react';

import { EditIconButtonLink } from '../../../components/EditIconButtonLink';
import { RemoveTextConfirmationDialog } from '../../../components/RemoveTextConfirmationDialog';
import { ShowIfCan } from '../../../hocs/ShowIfCan';
import { useAccessToken } from '../../../hooks/useAccessToken';
import { useAccountRoles } from '../../../hooks/useAccountRoles';
import { RoleService } from '../../../services/RoleService';
import { ActionChips } from '../data/ActionChips';
import { MediaRequestState } from '../data/MediaRequestState';
import { MediaRequest } from '../data/MediaRequest';
import { StateChips } from '../data/StateChips';
import { TypeChips } from '../data/TypeChips';
import { LookupChip } from './LookupChip';

interface Properties {
	request: MediaRequest;
	onStateChange(id: string, state: MediaRequestState): void;
	onRemove(id: string): Promise<void>;
}

export function MediaRequestListRow({ request, onStateChange, onRemove }: Properties): JSX.Element {
	const accessToken = useAccessToken();
	const ownsMediaRequest = useMemo(() => accessToken?.id === request.requestedById, [accessToken?.id, request.requestedById]);

	const accountRoles = useAccountRoles();
	const accountRoleService = useMemo(() => (new RoleService(accountRoles)), [accountRoles]);
	const canModerate = useMemo(() => (accountRoleService.can(['media-request:moderator'])), [accountRoleService]);

	const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

	const handleRemoveDialogOpen = () => {
		setRemoveDialogOpen(true);
	};

	const handleRemoveDialogClose = async (confirmed: boolean) => {
		setRemoveDialogOpen(false);

		if (confirmed) {
			await onRemove(request.id);
		}
	};

	return (
		<TableRow
			sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
		>
			<TableCell>
				{request.name}
				&nbsp;
				(
				{request.year}
				)
			</TableCell>
			<TableCell align='right'>
				{(ownsMediaRequest || canModerate) && (
					<>
						<ShowIfCan roles={['media-request:moderator']}>
							<EditIconButtonLink to={`/media-request/edit/${request.id}`} />
						</ShowIfCan>
						<RemoveTextConfirmationDialog
							open={removeDialogOpen}
							onClose={handleRemoveDialogClose}
							itemType={`${request.name} (${request.year})`}
							confirmText='remove'
						/>
						<IconButton
							onClick={handleRemoveDialogOpen}
							aria-label='remove'
							size='small'
							sx={{
								textTransform: 'none',
								color: 'inherit'
							}}
						>
							<Delete fontSize='small' />
						</IconButton>
					</>
				)}
			</TableCell>
			<TableCell align='center'>
				<Link
					aria-label='Open URL'
					size='small'
					component={IconButton}
					title='Open reference URL'
					href={request.url}
					target='_blank'
					sx={{
						color: 'inherit'
					}}
				>
					<OpenInNew fontSize='small' />
				</Link>
			</TableCell>
			<TableCell align='center'>
				<LookupChip value={request.type} map={TypeChips} />
			</TableCell>
			<TableCell align='center'>
				<LookupChip value={request.action} map={ActionChips} />
			</TableCell>
			<TableCell align='center'>
				{request.comment && (
					<Tooltip title={request.comment}>
						<IconButton>
							<Chat />
						</IconButton>
					</Tooltip>
				)}
				{request.expedite && (
					<Tooltip title='Expedite'>
						<IconButton>
							<FastForward />
						</IconButton>
					</Tooltip>
				)}
			</TableCell>
			<TableCell align='center'>
				{request.requestedByUsername}
			</TableCell>
			<TableCell align='center'>
				{canModerate ? (
					<LookupChip value={request.state} map={StateChips} onOptionClick={state => onStateChange(request.id, state as MediaRequestState)} />
				) : (
					<LookupChip value={request.state} map={StateChips} />
				)}
			</TableCell>
		</TableRow>
	);
}
