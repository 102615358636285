import { Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { EnrichedGeolocationCoordinates } from 'react-hook-geolocation';
import { useParams } from 'react-router-dom';

import { LoadingPlaceholder } from '../../../components/LoadingPlaceholder';
import { Page } from '../../../components/Page';
import { AstroDaysStage } from '../components/AstroDaysStage';
import { GeolocationPositionErrorAlert } from '../components/GeolocationPositionErrorAlert';
import { AstroDayAxis, AstroDayColorMap, DrawOptions } from '../services/AstroDaysDrawer';
import { AstroDaysService } from '../services/AstroDaysService';
import { AstroPhaseFactory } from '../services/AstroPhaseFactory';
import { AstroTimeEventSorter } from '../services/AstroTimeEventSorter';
import { DateService } from '../services/DateService';
import { IAstroDay } from '../services/IAstroDay';
import { IAstroDaysService } from '../services/IAstroDaysService';
import { MoonDayFactory } from '../services/MoonDayFactory';
import { MoonTimeColor } from '../services/MoonTimeColor';
import { SunCalcFacade } from '../services/SunCalcFacade';
import { SunDayFactory } from '../services/SunDayFactory';
import { SunTimeColor } from '../services/SunTimeColor';

const sunCalcFacade = new SunCalcFacade();
const astroTimeEventSorter = new AstroTimeEventSorter();
const astroPhaseFactory = new AstroPhaseFactory();
const sunYearService = new AstroDaysService(
	new DateService(),
	new SunDayFactory(
		sunCalcFacade,
		astroTimeEventSorter,
		astroPhaseFactory
	)
);
const moonYearService = new AstroDaysService(
	new DateService(),
	new MoonDayFactory(
		sunCalcFacade,
		astroTimeEventSorter,
		astroPhaseFactory
	)
);

type Mode = 'sun' | 'moon';
type Period = 'day' | 'month' | 'quarter' | 'year';

type ModeEntry = {
	colorMap: AstroDayColorMap;
	service: IAstroDaysService;
	periods: Record<Period, PeriodEntry>;
};

type PeriodEntry = {
	title: string;
	numberOfDays: number;
	dayThickness: number;
};

const modes: Record<Mode, ModeEntry> = {
	sun: {
		colorMap: SunTimeColor,
		service: sunYearService,
		periods: {
			day: {
				title: 'Sun - Day',
				numberOfDays: 1,
				dayThickness: 320
			},
			month: {
				title: 'Sun - Month',
				numberOfDays: 30,
				dayThickness: 12
			},
			quarter: {
				title: 'Sun - Quarter',
				numberOfDays: 90,
				dayThickness: 4
			},
			year: {
				title: 'Sun - Year',
				numberOfDays: 365,
				dayThickness: 2
			}
		}
	},
	moon: {
		colorMap: MoonTimeColor,
		service: moonYearService,
		periods: {
			day: {
				title: 'Moon - Day',
				numberOfDays: 1,
				dayThickness: 320
			},
			month: {
				title: 'Moon - Month',
				numberOfDays: 30,
				dayThickness: 12
			},
			quarter: {
				title: 'Moon - Quarter',
				numberOfDays: 90,
				dayThickness: 4
			},
			year: {
				title: 'Moon - Year',
				numberOfDays: 365,
				dayThickness: 2
			}
		}
	}
};

export function getNumberOfDays(mode: Mode, period: Period): number {
	return modes[mode].periods[period].numberOfDays;
}

export function getAstroDaysDrawerOptions(mode: Mode, period: Period, date: Date): DrawOptions {
	return {
		colorMap: modes[mode].colorMap,
		dayAlong: AstroDayAxis.Y,
		dayThickness: modes[mode].periods[period].dayThickness,
		totalDayLength: 144 * 2,
		now: date
	};
}

type Properties = {
	mode: Mode;
	geolocation: EnrichedGeolocationCoordinates;
};

export function SunMoonLight({ mode, geolocation }: Properties): JSX.Element {
	const { period } = useParams();

	if (!(period && period in modes[mode].periods)) {
		throw new Error(`Unknown period '${period ?? ''}' for mode ${mode}`);
	}

	console.log(`SunMoonLight - Mode: ${mode}, Period: ${period ?? ''}`, modes[mode]);

	const theDate = useMemo(() => new Date(), []);
	const [astroDays, setAstroDays] = useState<IAstroDay[]>();
	const [astroDaysOptions, setAstroDaysOptions] = useState<DrawOptions>();

	useEffect(() => {
		const { latitude, longitude, altitude } = geolocation;
		if (latitude && longitude) {
			const days = modes[mode].service.getDays(theDate, getNumberOfDays(mode, period as Period), latitude, longitude, altitude ?? undefined);
			console.log('SunMoonLight - Days', days);
			setAstroDays(days);
			setAstroDaysOptions(getAstroDaysDrawerOptions(mode, period as Period, theDate));
		}
	}, [geolocation, mode, period, theDate]);

	return (
		<Page title='Sunny Shine'>
			<Typography variant='h4' gutterBottom>
				{modes[mode].periods[period as Period].title}
			</Typography>
			{geolocation.error && <GeolocationPositionErrorAlert error={geolocation.error} />}
			{!geolocation.error && !(geolocation.latitude && geolocation.longitude) && (
				<LoadingPlaceholder
					isLoading
					label='Waiting for location'
				/>
			)}
			{(geolocation.latitude && geolocation.longitude && !astroDays) && (
				<LoadingPlaceholder
					isLoading
					label='Calculating times'
				/>
			)}
			{astroDays && astroDaysOptions && (
			// If I want to scale to a fixed size on screen, I think I need to basically render at a high resolution, get as png, then scale down and render the image.
				<Grid
					container
					spacing={2}
					direction="column"
					alignItems="center"
				>
					<Grid item xs={12}>
						<AstroDaysStage
							days={astroDays}
							options={astroDaysOptions}
						/>
					</Grid>
				</Grid>
			)}
		</Page>
	);
}
