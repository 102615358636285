import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useProvideAuth } from '../hooks/useProvideAuth';

export function ProvideAuth({ children }: InferProps<typeof ProvideAuth.propTypes>): JSX.Element {
	const auth = useProvideAuth();
	return (
		<AuthContext.Provider value={auth}>
			{children}
		</AuthContext.Provider>
	);
}

ProvideAuth.propTypes = {
	children: PropTypes.node.isRequired
};
