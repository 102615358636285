import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
	Box, IconButton,
	Paper, Stack, Typography
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { InputMultiCheckbox } from '../../../components/form/InputMultiCheckbox';
import { InputTextField } from '../../../components/form/InputTextField';
import { Page } from '../../../components/Page';
import { RemoveTextConfirmationDialog } from '../../../components/RemoveTextConfirmationDialog';
import { ApiErrorAlert } from '../../Auth/components/ApiErrorAlert';
import { LoadingPlaceholder } from '../../../components/LoadingPlaceholder';
import { EditWalletFormData, WalletSchema } from '../data/WalletSchema';
import {
	useSupportedPoolsQuery,
	useWalletEditMutation,
	useWalletQuery,
	useWalletRemoveMutation
} from '../WalletWatch.queries';

export function EditWallet(): JSX.Element {
	const { id: walletId } = useParams();

	const navigate = useNavigate();

	const {
		handleSubmit, control, reset, setValue, formState: {
			errors, isDirty, isValid, isSubmitting
		}
	} = useForm<EditWalletFormData>({
		mode: 'all',
		resolver: yupResolver(WalletSchema)
	});

	const wallet = useWalletQuery(walletId ?? '', !!walletId);
	useEffect(() => {
		reset(wallet.data);
	}, [reset, wallet.data]);

	const walletSymbol = wallet.data?.symbol;
	const supportedPools = useSupportedPoolsQuery(walletSymbol, !!walletSymbol);
	const poolOptions = useMemo(() => supportedPools.data?.map(poolName => ({
		label: poolName,
		value: poolName
	})) ?? [], [supportedPools.data]);

	if (Object.keys(errors).length > 0) {
		console.log(`Validation errors: ${JSON.stringify(errors, null, 4)}`);
	}

	const edit = useWalletEditMutation();
	const remove = useWalletRemoveMutation();

	const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

	const handleRemoveDialogOpen = () => {
		setRemoveDialogOpen(true);
	};

	const handleRemoveDialogClose = async (confirmed: boolean) => {
		if (confirmed) {
			remove.mutate(walletId ?? '', {
				onSuccess: () => navigate('/wallet-watch/wallets/list')
			});
		}

		setRemoveDialogOpen(false);
	};

	const onValid = async (data: EditWalletFormData) => {
		await edit.mutateAsync({ walletId: walletId ?? '', data }, {
			onSuccess: () => navigate('/wallet-watch/wallets/list')
		});
	};

	return (
		<Page title={`Edit ${wallet.data?.name ?? ''} - Wallet Watch`}>
			<Paper
				elevation={0}
				sx={{
					p: 3
				}}
			>
				{wallet.data && (
					<RemoveTextConfirmationDialog
						open={removeDialogOpen}
						onClose={handleRemoveDialogClose}
						itemType='wallet'
						confirmText={`${wallet.data.symbol}:${wallet.data.address}`}
					/>
				)}
				{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
				<form onSubmit={handleSubmit(onValid)}>
					<Stack spacing={3}>
						<Box
							display='flex'
						>
							<Typography variant='h6'>
								Edit wallet
							</Typography>
							<Box sx={{ flexGrow: 1 }} />
							{!wallet.isLoading && (
								<IconButton
									onClick={handleRemoveDialogOpen}
									aria-label='remove'
									color='warning'
									size='small'
									sx={{
										textTransform: 'none'
									}}
								>
									<Delete fontSize='small' />
								</IconButton>
							)}
						</Box>

						{wallet.isLoading ? (
							<LoadingPlaceholder
								isLoading={wallet.isLoading}
								label='Loading Wallet...'
							/>
						) : (
							<>
								<InputTextField
									name='name'
									control={control}
									label='Name'
									disabled={wallet.isLoading}
								/>
								<InputTextField
									name='symbol'
									control={control}
									label='Symbol'
									disabled
								/>
								<InputTextField
									name='address'
									control={control}
									label='Address'
									disabled
								/>
								<InputMultiCheckbox
									name='pools'
									control={control}
									label='Watch wallet on pools'
									setValue={setValue}
									initialValue={wallet.data?.pools || []}
									options={poolOptions}
								/>
								<LoadingButton
									size='large'
									type='submit'
									variant='contained'
									disabled={!isDirty || !isValid}
									loading={isSubmitting}
								>
									Save
								</LoadingButton>
							</>
						)}
						{wallet.error && (
							<ApiErrorAlert apiError={wallet.error} />
						)}
						{supportedPools.error && (
							<ApiErrorAlert apiError={supportedPools.error} />
						)}
						{edit.error && (
							<ApiErrorAlert apiError={edit.error} />
						)}
						{remove.error && (
							<ApiErrorAlert apiError={remove.error} />
						)}
					</Stack>
				</form>
			</Paper>
		</Page>
	);
}
