import {
	Button, Stack, Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { InputCheckbox } from '../../../components/form/InputCheckbox';
import { InputTextField } from '../../../components/form/InputTextField';
import { InputToggleGroup, InputToggleGroupOptionsEntry } from '../../../components/form/InputToggleGroup';
import { Page } from '../../../components/Page';
import { ApiErrorAlert } from '../../Auth/components/ApiErrorAlert';
import { AddMediaRequestsFormData, MediaRequestSchema } from '../data/MediaRequestSchema';
import { ColorIconChipData } from '../components/ColorIconChip';
import { ActionChips } from '../data/ActionChips';
import { TypeChips } from '../data/TypeChips';
import { useMediaRequestAddMutation } from '../MediaRequest.queries';

const defaultValues: AddMediaRequestsFormData = {
	name: '',
	year: '',
	url: '',
	type: '',
	action: '',
	expedite: false,
	comment: ''
};

function chipsMappingToOptions(mapping: Record<string, ColorIconChipData>): InputToggleGroupOptionsEntry[] {
	const entries = [];

	for (const [value, data] of Object.entries(mapping)) {
		const color = (data.color === 'default' || !(data.color)) ? 'standard' : data.color as InputToggleGroupOptionsEntry['color'];
		entries.push({
			value,
			label: data.label,
			icon: data.icon,
			color
		});
	}

	return entries;
}

export function AddMediaRequests(): JSX.Element {
	const navigate = useNavigate();

	const {
		handleSubmit, control, reset, formState: {
			errors, isDirty, isValid, isSubmitting
		}
	} = useForm<AddMediaRequestsFormData>({
		defaultValues,
		mode: 'all',
		resolver: yupResolver(MediaRequestSchema)
	});

	if (Object.keys(errors).length > 0) {
		console.log(`Validation errors: ${JSON.stringify(errors, null, 4)}`);
	}

	const add = useMediaRequestAddMutation();

	const actionOptions = useMemo((): InputToggleGroupOptionsEntry[] => chipsMappingToOptions(ActionChips), []);
	const typeOptions = useMemo((): InputToggleGroupOptionsEntry[] => chipsMappingToOptions(TypeChips), []);

	const onValid = async (data: AddMediaRequestsFormData) => {
		await add.mutateAsync(data, {
			onSuccess: () => navigate('/media-request')
		});
	};

	return (
		<Page title='Add Media Request' maxWidth='md'>
			{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
			<form onSubmit={handleSubmit(onValid)}>
				<Stack spacing={3}>
					<Typography variant='h6'>
						Add Media Request
					</Typography>
					<InputTextField
						name='name'
						control={control}
						label='Name'
					/>
					<InputTextField
						name='year'
						control={control}
						label='Year'
					/>
					<InputTextField
						name='url'
						control={control}
						label='URL'
					/>
					<Stack
						spacing={2}
						sx={{
							border: '1px solid lightgrey',
							borderRadius: '5px',
							p: 1
						}}
					>
						<Typography>
							Type
						</Typography>
						<InputToggleGroup
							name='type'
							control={control}
							options={typeOptions}
							exclusive
							orientation='vertical'
						/>
					</Stack>
					<Stack
						spacing={2}
						sx={{
							border: '1px solid lightgrey',
							borderRadius: '5px',
							p: 1
						}}
					>
						<Typography>
							Action
						</Typography>
						<InputToggleGroup
							name='action'
							control={control}
							options={actionOptions}
							exclusive
							orientation='vertical'
						/>
					</Stack>
					<Stack>
						<InputCheckbox
							name='expedite'
							control={control}
							label='Expedite'
						/>
					</Stack>
					<InputTextField
						name='comment'
						control={control}
						label='Comment'
					/>
					{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
					<LoadingButton
						size='large'
						type='submit'
						variant='contained'
						disabled={!isDirty || !isValid}
						loading={isSubmitting}
					>
						Add
					</LoadingButton>
					{add.error && (
						<ApiErrorAlert apiError={add.error} />
					)}
					<Button
						disabled={!isDirty}
						onClick={() => reset()}
						variant='outlined'
					>
						Reset
					</Button>
				</Stack>
			</form>
		</Page>
	);
}
