import {
	Checkbox, CheckboxProps, FormControlLabel
} from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

import { InputProperties } from './InputProperties';

interface InputCheckboxExtraProperties {
	label: string;
}

export function InputCheckbox({
	name, control, label, ...others
}: InputProperties & InputCheckboxExtraProperties & CheckboxProps): JSX.Element {
	return (
		<Controller
			name={name}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			control={control}
			render={({
				field: { onChange, value }
			}) => (
				<FormControlLabel
					control={(
						<Checkbox
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							checked={value}
							onChange={onChange}
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...others}
						/>
					)}
					label={label}
				/>
			)}
		/>
	);
}
