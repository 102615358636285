import { createContext } from 'react';

export interface AuthContextValue {
	isAuthenticated: boolean;
	register(username: string, password: string): Promise<void>;
	login(username: string, password: string, persist: boolean): Promise<void>;
	logout(): Promise<void>;
}

export const AuthContext = createContext<AuthContextValue | undefined>(undefined);
