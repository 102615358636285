import {
	Dispatch, SetStateAction, useEffect, useState
} from 'react';

export function useStateWithLocalStorage(localStorageKey: string, defaultState: string): [string, Dispatch<SetStateAction<string>>] {
	const [value, setValue] = useState<string>(localStorage.getItem(localStorageKey) || defaultState);

	useEffect(() => {
		localStorage.setItem(localStorageKey, value);
	}, [localStorageKey, value]);

	return [value, setValue];
}
