import {
	Box, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Page } from '../../../components/Page';
import { useAccessToken } from '../../../hooks/useAccessToken';
import { AuthEngine } from '../../../services/AuthEngine';

const useWidth = () => {
	const [width, setWidth] = useState(window.innerWidth);
	useEffect(() => {
		const onResize = () => {
			setWidth(window.innerWidth);
		};
		window.addEventListener('resize', onResize);
		return () => window.removeEventListener('resize', onResize);
	}, []);
	return width;
};

export function TechnicalDetails(): JSX.Element {
	const width = useWidth();

	const currentAccessToken = AuthEngine.getAccessToken();

	const accessTokenHeader = useAccessToken({ header: true });
	const accessToken = useAccessToken();

	return (
		<Page title='Tech. Details - Account'>
			<Typography variant='h4' gutterBottom>
				Technical Details
			</Typography>
			<Box
				sx={{
					p: 2,
					border: '1px dashed grey',
					borderRadius: '5px',
					mb: 2
				}}
			>
				<Typography variant='h6' gutterBottom>
					Access token
				</Typography>
				<Typography
					style={{
						width: width * 0.5,
						wordWrap: 'break-word'
					}}
				>
					{currentAccessToken}
				</Typography>
			</Box>
			<Box
				sx={{
					p: 2,
					border: '1px dashed grey',
					borderRadius: '5px'
				}}
			>
				<Typography variant='h6' gutterBottom>
					Decoded access token
				</Typography>
				<pre>
					{JSON.stringify(accessTokenHeader, null, 4)}
				</pre>
				<pre>
					{JSON.stringify(accessToken, null, 4)}
				</pre>
			</Box>
		</Page>
	);
}
