import { AuthedApiService } from '../../../services/AuthedApiService';

export type ExchangeRates = Record<string, number>;

export class ExchangeRateService {
	static cache = new Map<string, Promise<ExchangeRates>>();

	static async getRates(symbol: string): Promise<ExchangeRates> {
		const cachedResult = ExchangeRateService.cache.get(symbol);
		if (cachedResult) {
			return cachedResult;
		}

		const result = AuthedApiService.get<ExchangeRates>(`/wallet-watch/rates/${symbol}`);

		ExchangeRateService.cache.set(symbol, result);

		return result;
	}
}
