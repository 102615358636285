// This maps to the color of the period _after_ this point in time
export enum SunTimeColor {
	'nadir' = 'rgb(0, 8, 27)',
	'nightEnd' = 'rgb(2, 25, 64)', // Twilight (astronomical)
	'nauticalDawn' = 'rgb(12, 48, 109)', // Twilight (nautical)
	'dawn' = 'rgb(64, 109, 185)', // Twilight (civil)
	'sunrise' = 'rgb(245, 123, 117)',
	'sunriseEnd' = 'rgb(255, 232, 129)',
	'goldenHourEnd' = 'rgb(255, 246, 171)',
	'solarNoon' = 'rgb(255, 246, 171)',
	'goldenHour' = 'rgb(255, 232, 129)',
	'sunsetStart' = 'rgb(245, 123, 117)',
	'sunset' = 'rgb(64, 109, 185)', // Twilight (civil)
	'dusk' = 'rgb(12, 48, 109)', // Twilight (nautical)
	'nauticalDusk' = 'rgb(2, 25, 64)', // Twilight (astronomical)
	'night' = 'rgb(0, 8, 27)'
}
