import React, { ReactNode } from 'react';
import {
	Box, Divider, Drawer, DrawerProps, Typography
} from '@mui/material';

import { DrawerHeader } from '../components/DrawerHeader';

interface ResponsiveDrawerProperties {
	width: number;
	title?: string;
	open: boolean;
	onClose: DrawerProps['onClose'];
	children: ReactNode;
}

ResponsiveDrawer.defaultProps = {
	title: undefined
};

export function ResponsiveDrawer({
	width, title, open, onClose, children
}: ResponsiveDrawerProperties): JSX.Element {
	return (
		<Box
			component='nav'
			sx={{ width: { sm: width }, flexShrink: { sm: 0 } }}
			aria-label='Menu'
		>
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Drawer
				variant='temporary'
				open={open}
				onClose={onClose}
				ModalProps={{
					keepMounted: true
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width }
				}}
			>
				<DrawerHeader>
					{title && (
						<Typography
							variant='h6'
							noWrap
						>
							{title}
						</Typography>
					)}
				</DrawerHeader>
				<Divider />
				{children}
			</Drawer>
			<Drawer
				variant='permanent'
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width }
				}}
				open
			>
				<DrawerHeader>
					{title && (
						<Typography
							variant='h6'
							noWrap
						>
							{title}
						</Typography>
					)}
				</DrawerHeader>
				<Divider />
				{children}
			</Drawer>
		</Box>
	);
}
