import {
	Box, Button,
	IconButton, Stack, Typography
} from '@mui/material';
import { Apps } from '@mui/icons-material';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import MenuPopover from '../../components/MenuPopover';
import { useAccessToken } from '../../hooks/useAccessToken';
import { useAuth } from '../../hooks/useAuth';
import { getAppRoutesByAuthState } from '../../app-routes';

export function AppSelectorPopover(): JSX.Element | null {
	const anchorReference = useRef(null);
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const auth = useAuth();
	const roles = useAccessToken()?.roles ?? [];

	const appRoutes = getAppRoutesByAuthState(auth.isAuthenticated, roles);
	if (appRoutes.length === 0) {
		return null;
	}

	const gridTemplateColumns = `repeat(${Math.min(appRoutes.length, 3)}, 1fr)`;

	return (
		<>
			<IconButton
				ref={anchorReference}
				onClick={handleOpen}
				color="inherit"
			>
				<Apps />
			</IconButton>
			<MenuPopover
				open={open}
				onClose={handleClose}
				anchorEl={anchorReference.current}
			>
				<Box
					sx={{
						p: 1,
						display: 'grid',
						gap: 1,
						gridTemplateColumns
					}}
				>
					{appRoutes.map(route => (
						<Button
							component={Link}
							to={route.to}
							key={route.to}
							onClick={handleClose}
							sx={{
								textTransform: 'none',
								color: 'inherit'
							}}
						>
							<Stack>
								<Typography
									align='center'
								>
									{route.icon}
								</Typography>

								<Typography
									align='center'
									variant='body2'
								>
									{route.title}
								</Typography>
							</Stack>
						</Button>
					))}
				</Box>
			</MenuPopover>
		</>
	);
}
