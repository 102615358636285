import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AccessTime, DarkMode, LightMode } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { useStateWithLocalStorage } from '../../components/useStateLocalStorage';

const StyledToggleButton = styled(ToggleButton)({
	color: 'inherit'
});

export function LightDarkModeToggle() : JSX.Element {
	const [mode, setMode] = useStateWithLocalStorage('displayMode', 'automatic');

	const handleMode = (
		event: React.MouseEvent<HTMLElement>,
		value: string | null
	) => {
		if (!value) {
			return;
		}

		setMode(value);
	};

	return (
		<ToggleButtonGroup
			value={mode}
			exclusive
			onChange={handleMode}
			aria-label="Colour display mode"
			size='small'
		>
			<StyledToggleButton
				value="light"
				aria-label="Light mode"
			>
				<LightMode />
			</StyledToggleButton>
			<StyledToggleButton
				value="dark"
				aria-label="Dark mode"
			>
				<DarkMode />
			</StyledToggleButton>
			<StyledToggleButton
				value="automatic"
				aria-label="Automatic mode"
			>
				<AccessTime />
			</StyledToggleButton>
		</ToggleButtonGroup>
	);
}
