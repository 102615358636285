import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
	Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { InputTextField } from './form/InputTextField';

/*
NB: This is perhaps not the optimal way of handling an async request,
as there is no way to show the APIError (unless I pass it in from outside)
BUT: It's good enough for now.
 */

interface Properties {
	open: boolean;
	onClose(confirmed: boolean): Promise<void>;
	itemType: string;
	confirmText: string;
}

export function RemoveTextConfirmationDialog({
	open, onClose, itemType, confirmText
}: Properties): JSX.Element {
	const ConfirmSchema = yup.object({
		confirm: yup.string()
			.matches(new RegExp(`^${confirmText}$`), 'Does not match')
			.required('')
	});

	type ConfirmFormData = yup.InferType<typeof ConfirmSchema>;

	const {
		handleSubmit, control, formState: {
			errors, isValid, isSubmitting
		}
	} = useForm<ConfirmFormData>({
		mode: 'all',
		resolver: yupResolver(ConfirmSchema)
	});

	if (Object.keys(errors).length > 0) {
		console.log(`Validation errors: ${JSON.stringify(errors, null, 4)}`);
	}

	const onCancel = () => {
		void onClose(false);
	};

	const onSubmit = async () => {
		await onClose(true);
	};

	return (
		<Dialog open={open} onClose={onCancel}>
			{/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogTitle>
					Remove&nbsp;
					{itemType}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Type&nbsp;
						<strong>
							{confirmText}
						</strong>
						&nbsp;to confirm.
					</DialogContentText>
					{/* TODO: Disable browser autocomplete! */}
					<InputTextField
						name='confirm'
						control={control}
						label='Type to confirm'
						margin='normal'
						variant='standard'
						fullWidth
						type='text'
						autoComplete='new-password'
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={onCancel}>Cancel</Button>
					<LoadingButton
						type='submit'
						variant='contained'
						disabled={!isValid}
						loading={isSubmitting}
					>
						Remove
					</LoadingButton>
				</DialogActions>
			</form>
		</Dialog>
	);
}
