import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';

type Properties = {
	isLoading: boolean;
	label?: string;
};

LoadingPlaceholder.defaultProps = {
	label: undefined
};

export function LoadingPlaceholder({ isLoading, label }: Properties): JSX.Element | null {
	if (!isLoading) {
		return null;
	}

	return (
		<Box
			sx={{
				p: 2,
				width: '100%'
			}}
		>
			{label
				&& (
					<Typography>
						{label}
					</Typography>
				)}
			<LinearProgress />
		</Box>
	);
}
