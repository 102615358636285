import { DateTime } from 'luxon';

import { AstroPhaseFactory } from './AstroPhaseFactory';
import { AstroTimeEvent } from './AstroTimeEvent';
import { AstroTimeEventSorter } from './AstroTimeEventSorter';
import { IAstroDayFactory } from './IAstroDayFactory';
import { SunCalcFacade, SunTimes } from './SunCalcFacade';
import { SunDay } from './SunDay';

export class SunDayFactory implements IAstroDayFactory {
	private readonly sunCalcFacade: SunCalcFacade;

	private readonly astroTimeEventSorter: AstroTimeEventSorter;

	private readonly astroPhaseFactory: AstroPhaseFactory;

	constructor(sunCalcFacade: SunCalcFacade, astroTimeEventSorter: AstroTimeEventSorter, astroPhaseFactory: AstroPhaseFactory) {
		this.sunCalcFacade = sunCalcFacade;
		this.astroTimeEventSorter = astroTimeEventSorter;
		this.astroPhaseFactory = astroPhaseFactory;
	}

	public getDay(date: Date, latitude: number, longitude: number, altitude?: number): SunDay {
		const sunTimes = this.sunCalcFacade.getSunTimes(date, latitude, longitude, altitude);
		let sunTimesArray = SunDayFactory.getValidSunTimesAsArray(sunTimes);
		sunTimesArray = this.astroTimeEventSorter.sortByDate(sunTimesArray);
		const sunPhases = this.astroPhaseFactory.getPhases(sunTimesArray);
		const sunDay = new SunDay(date, sunTimesArray, sunPhases);

		return sunDay;
	}

	public static getValidSunTimesAsArray(sunTimes: SunTimes): AstroTimeEvent[] {
		const entries = [];
		let k: keyof SunTimes;
		for (k in sunTimes) {
			// We skip the peaks, as they incur no transition for the segments as currently defined
			// And for some reason, they are often on another date?
			if (['nadir', 'solarNoon'].includes(k)) {
				continue;
			}

			if (Object.prototype.hasOwnProperty.call(sunTimes, k)) {
				const date = sunTimes[k];
				if (date && DateTime.fromJSDate(date).isValid) {
					entries.push({
						date,
						description: k
					});
				}
			}
		}

		return entries;
	}
}
