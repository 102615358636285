import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
	Divider, List
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import { ListItemLink } from '../../components/ListItemLink';
import { PersistentDrawerShell } from '../../layout/PersistentDrawerShell/PersistentDrawerShell';
import { AddWallet } from './views/AddWallet';
import { EditWallet } from './views/EditWallet';
import { Settings } from './views/Settings';
import { WalletList } from './views/WalletList';

export function WalletWatch(): JSX.Element {
	const menu = (
		<>
			<List>
				<ListItemLink
					icon={<AddBoxIcon />}
					primary='Add wallet'
					to='/wallet-watch/wallets/add'
				/>
			</List>
			<Divider />
			<List>
				<ListItemLink
					icon={<FormatListBulletedIcon />}
					primary='Wallets'
					to='/wallet-watch/wallets/list'
				/>
			</List>
			{/* <Divider /> */}
			{/* <List> */}
			{/*	<ListItemLink */}
			{/*		icon={<SettingsIcon />} */}
			{/*		primary='Settings' */}
			{/*		to='/wallet-watch/settings' */}
			{/*	/> */}
			{/* </List> */}
		</>
	);

	return (
		<Routes>
			<Route
				path='/*'
				element={(
					<PersistentDrawerShell
						menuItems={menu}
						title='Wallet Watch'
					/>
				)}
			>
				<Route index element={<WalletList />} />
				<Route path='wallets'>
					<Route path='add' element={<AddWallet />} />
					<Route path='edit/:id' element={<EditWallet />} />
					<Route path='list' element={<WalletList />} />
				</Route>
				<Route path='settings' element={<Settings />} />
			</Route>
		</Routes>
	);
}
