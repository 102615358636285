import { useContext } from 'react';
import { AuthContext, AuthContextValue } from '../contexts/AuthContext';

export const useAuth = (): AuthContextValue => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('AuthContext must be used inside of a AuthContext.Provider');
	}

	return context;
};
