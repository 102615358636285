import React from 'react';
import { Link } from 'react-router-dom';
import {
	Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@mui/material';

import { LoadingPlaceholder } from '../../../components/LoadingPlaceholder';
import { Page } from '../../../components/Page';
import { ApiErrorAlert } from '../../Auth/components/ApiErrorAlert';
import { ManageHabitsListRow } from '../components/ManageHabitsListRow';
import { useHabitsQuery } from '../Tracker.queries';

export function ManageHabits(): JSX.Element {
	const habits = useHabitsQuery();

	return (
		<Page title='Manage Habits - Tracker'>
			<Typography
				variant='h6'
				sx={{ mb: 2 }}
			>
				Manage Habits
			</Typography>
			<Button
				variant='outlined'
				component={Link}
				to='/tracker/habits/add'
				sx={{ mb: 2 }}
			>
				Add new habit
			</Button>
			<TableContainer component={Paper} elevation={0}>
				<Table aria-label='Habits'>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell />
							<TableCell>Ordering</TableCell>
							<TableCell>Direct tracking</TableCell>
							<TableCell>Require count</TableCell>
							<TableCell>Options</TableCell>
							<TableCell>Composed of</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{habits.data && habits.data.map(habit => <ManageHabitsListRow key={habit.id} habit={habit} habits={habits.data} />)}
					</TableBody>
				</Table>
				<LoadingPlaceholder
					isLoading={habits.isLoading}
					label='Loading Habits...'
				/>
				{habits.error && (
					<ApiErrorAlert apiError={habits.error} sx={{ m: 2 }} />
				)}
			</TableContainer>
		</Page>
	);
}
