import {
	ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps
} from '@mui/material';
import { ToggleButtonTypeMap } from '@mui/material/ToggleButton/ToggleButton';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';

import { InputProperties } from './InputProperties';

export interface InputToggleGroupOptionsEntry {
	value: string; // The key
	label: string;
	icon?: ReactElement;
	color?: ToggleButtonTypeMap['props']['color'];
}

interface InputToggleGroupProperties extends InputProperties {
	options: InputToggleGroupOptionsEntry[];
}

export function InputToggleGroup({
	name,
	control,
	options,
	...others
}: InputToggleGroupProperties & ToggleButtonGroupProps): JSX.Element {
	return (
		<Controller
			name={name}
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			control={control}
			render={({
				field: { onChange, value }
			}) => (
				<ToggleButtonGroup
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					value={value}
					onChange={onChange}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...others}
				>
					{options.map(option => (
						<ToggleButton
							key={option.value}
							value={option.value}
							color={option.color ?? 'standard'}
						>
							{option.icon}
							&nbsp;
							{option.label}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			)}
		/>
	);
}
