import { AuthedApiService } from '../../services/AuthedApiService';
import { Habit } from './data/Habit';

type HabitAddEditProperties = Omit<Habit, 'id' | 'owner'>;

export class HabitService {
	static async add(data: HabitAddEditProperties): Promise<void> {
		await AuthedApiService.post('/tracker/habits', data);
	}

	static async edit(id: string, data: HabitAddEditProperties): Promise<void> {
		await AuthedApiService.patch(`/tracker/habits/${id}`, data);
	}

	static get(id: string): Promise<Habit> {
		return AuthedApiService.get<Habit>(`/tracker/habits/${id}`);
	}

	static async remove(id: string): Promise<void> {
		await AuthedApiService.delete(`/tracker/habits/${id}`);
	}

	static list(): Promise<Habit[]> {
		return AuthedApiService.get<Habit[]>('/tracker/habits');
	}

	static async getComposeOptions(habitId?: string): Promise<Habit[]> {
		const parameters: {
			id?: string;
		} = {};
		if (habitId) {
			parameters.id = habitId;
		}

		const habits = await AuthedApiService.get<Habit[]>('/tracker/habits/compose-options', parameters);
		return habits;
	}
}
