import { DateTime } from 'luxon';
import { IAstroDay } from './IAstroDay';
import { AstroPhase } from './AstroPhase';
import { RectData } from './RectData';

export interface AstroDaysDrawed {
	rects: RectData[];
	canvas: {
		width: number;
		height: number;
	};
}

export enum AstroDayAxis {
	X,
	Y
}

export type AstroDayColorMap = Record<string, string>;

export interface DrawOptions {
	colorMap: AstroDayColorMap;
	dayAlong: AstroDayAxis;
	dayThickness: number;
	totalDayLength: number;
	now?: Date; // Specifying this will draw a cross on that point in time
}

export class AstroDaysDrawer {
	public static draw(days: IAstroDay[], options: DrawOptions): AstroDaysDrawed {
		const totalThickness = days.length * options.dayThickness;

		const rectData: RectData[] = days.flatMap((day, index) => {
			const offset = index * options.dayThickness;
			const scaledPhases = AstroDaysDrawer.getScaledPhases(day.phases, options.totalDayLength, 0.5);
			return AstroDaysDrawer.getRectDataFor(scaledPhases, offset, options.colorMap, options.dayAlong, options.dayThickness);
		});
		if (options.now) {
			rectData.push(...AstroDaysDrawer.getRectDataForCrossOnNow(days, options.dayAlong, options.dayThickness, options.now, options.totalDayLength));
		}

		const canvas = {
			width: options.dayAlong === AstroDayAxis.X ? options.totalDayLength : totalThickness,
			height: options.dayAlong === AstroDayAxis.X ? totalThickness : options.totalDayLength
		};

		return {
			canvas,
			rects: rectData
		};
	}

	/**
	 * @param phases - Must start 0 and not exceed a total length of 84.400
	 * @param totalDayLength
	 * @param belowOneCutoff - Entries larger than one pixel will get rounded, entries above this live will be enlarged to 1 pixel. Smaller entries will be removed.
	 */
	public static getScaledPhases(phases: AstroPhase[], totalDayLength: number, belowOneCutoff = 0.5): AstroPhase[] {
		const scaledPhases: AstroPhase[] = [];

		let pixelsDrawn = 0;
		for (const phase of phases) {
			const { duration, startAt } = phase;

			const startAtPixel = pixelsDrawn;
			let lengthInPixels;

			const trueLengthInPixels = (duration / 86_400) * totalDayLength;
			if (trueLengthInPixels < 1) {
				if (trueLengthInPixels < belowOneCutoff) {
					continue;
				}

				lengthInPixels = 1;
			}
			else {
				// TODO: 86_400 can be calculated as sum of lengths
				const trueStartAtSecondScaled = Math.round((startAt / 86_400) * totalDayLength);
				const trueStartIsEarly = trueStartAtSecondScaled < pixelsDrawn;
				const trueStartIsLate = trueStartAtSecondScaled > pixelsDrawn;

				if (trueStartIsEarly) {
					// If real time is early, we have previously counted too much
					lengthInPixels = Math.floor(trueLengthInPixels);
				}
				else if (trueStartIsLate) {
					// If real time is late, we have previously counted too little
					lengthInPixels = Math.ceil(trueLengthInPixels);
				}
				else {
					lengthInPixels = Math.round(trueLengthInPixels);
				}
			}

			pixelsDrawn += lengthInPixels;

			scaledPhases.push({
				description: phase.description,
				startAt: startAtPixel,
				duration: lengthInPixels
			});
		}

		return scaledPhases;
	}

	public static getRectDataFor(phases: AstroPhase[], offset: number, colorMap: Record<string, string>, dayAlong: AstroDayAxis, dayThickness: number): RectData[] {
		return phases.map(phase => {
			if (!(phase.description in colorMap)) {
				throw new Error(`${phase.description} was not found in the provided color map`);
			}

			if (dayAlong === AstroDayAxis.X) {
				return {
					x: phase.startAt,
					y: offset,
					width: phase.duration,
					height: dayThickness,
					fill: colorMap[phase.description]
				};
			}

			if (dayAlong === AstroDayAxis.Y) {
				return {
					x: offset,
					y: phase.startAt,
					width: dayThickness,
					height: phase.duration,
					fill: colorMap[phase.description]
				};
			}

			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			throw new Error(`Unknown axis: ${dayAlong}`);
		});
	}

	/**
	 * Returns cross for 'now'
	 * First line overlays all the phases of the day (iff days is more than 1 element long)
	 * Second line overlays the current time of day across all days
	 *
	 * LOL, as this operates on 'now', isn't it already assumed that this needs data for a day / 86_400 units?
	 * NB: This assumes all days to be the same length (sum of lengths of phases is sampled from first entry)
	 * @param days
	 * @param dayAlong
	 * @param dayThickness
	 * @param now
	 * @param totalDayLength
	 */
	public static getRectDataForCrossOnNow(days: IAstroDay[], dayAlong: AstroDayAxis, dayThickness: number, now: Date, totalDayLength: number): RectData[] {
		const theNow = DateTime.fromJSDate(now);
		const index = days.findIndex(day => DateTime.fromJSDate(day.date).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) === theNow.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY));
		if (index === -1) {
			throw new Error('Could not find now in the list of days');
		}

		const dayOffset = index * dayThickness;
		const totalThickness = days.length * dayThickness;

		const startOfDay = theNow.startOf('day');
		const timeOffset = theNow.diff(startOfDay).as('seconds');
		// TODO: GET 86_400 as argument or by calculation
		const timePixelOffset = Math.round((timeOffset / 86_400) * totalDayLength); // TODO: This should be rounded / capped, so it doesn't draw outside the canvas on the extreme values (add test for extreme values I guess :))

		const crossRects = [];

		// Render time component
		if (dayAlong === AstroDayAxis.X) {
			crossRects.push({
				x: timePixelOffset,
				y: 0,
				width: 1,
				height: totalThickness,
				fill: 'red',
				opacity: 0.6
			});
		}
		else {
			crossRects.push({
				x: 0,
				y: timePixelOffset,
				width: totalThickness,
				height: 1,
				fill: 'red',
				opacity: 0.6
			});
		}

		const renderDayComponent = days.length > 1;
		if (renderDayComponent) {
			if (dayAlong === AstroDayAxis.X) {
				crossRects.push({
					x: 0,
					y: dayOffset,
					width: totalDayLength,
					height: dayThickness,
					fill: 'red',
					opacity: 0.6
				});
			}
			else {
				crossRects.push({
					x: dayOffset,
					y: 0,
					width: dayThickness,
					height: totalDayLength,
					fill: 'red',
					opacity: 0.6
				});
			}
		}

		return crossRects;
	}
}
