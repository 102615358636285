import { AuthedApiService } from '../../../services/AuthedApiService';
import { CryptoSymbols } from '../data/Symbols';

export class SymbolService {
	static cachedCrypto: Promise<CryptoSymbols> | undefined;

	static async getCrypto(): Promise<CryptoSymbols> {
		if (SymbolService.cachedCrypto) {
			return SymbolService.cachedCrypto;
		}

		SymbolService.cachedCrypto = AuthedApiService.get<CryptoSymbols>('/wallet-watch/symbols/crypto');

		return SymbolService.cachedCrypto;
	}
}
