import { Popover, PopoverProps } from '@mui/material';
import React from 'react';

export default function MenuPopover({ children, sx, ...other }: PopoverProps) {
	return (
		<Popover
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			PaperProps={{
				sx: {
					...sx
				}
			}}
			/* eslint-disable-next-line react/jsx-props-no-spreading */
			{...other}
		>
			{children}
		</Popover>
	);
}
