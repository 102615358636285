import React from 'react';
import { Layer, Rect, Stage } from 'react-konva';

import { LoadingPlaceholder } from '../../../components/LoadingPlaceholder';
import { AstroDaysDrawer, DrawOptions } from '../services/AstroDaysDrawer';
import { IAstroDay } from '../services/IAstroDay';

type Properties = {
	days: IAstroDay[];
	options: DrawOptions;
};

export function AstroDaysStage({
	days,
	options

}: Properties): JSX.Element {
	console.log('AstroDaysStage Properties', {
		days,
		options
	});

	const drawed = AstroDaysDrawer.draw(days, options);
	console.log('AstroDaysStage - Drawed', drawed);

	if (!drawed) {
		return (
			<LoadingPlaceholder
				isLoading
				label='Calculating rectangles'
			/>
		);
	}

	return (
		<Stage width={drawed.canvas.width + 5} height={drawed.canvas.height + drawed.canvas.height + 5}>
			<Layer>
				{drawed.rects.map(rect => (
					<Rect
						key={`${rect.x} ${rect.y} ${rect.width} ${rect.height}`}
						x={rect.x}
						y={rect.y}
						width={rect.width}
						height={rect.height}
						fill={rect.fill}
						opacity={rect.opacity ?? 1}
					/>
				))}
			</Layer>
		</Stage>
	);
}
