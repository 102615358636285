import {
	Box, Button, ButtonBase, Stack, Typography
} from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';

import { MHidden } from '../../components/@material-extend';
import { Logo } from '../../components/Logo';
import { useAuth } from '../../hooks/useAuth';
import { AppSelectorPopover } from './AppSelectorPopover';
import { LightDarkModeToggle } from './LightDarkModeToggle';

interface HeaderProperties {
	title?: string;
}

Header.defaultProps = {
	title: undefined
};

export function Header({
	title
}: HeaderProperties): JSX.Element {
	const auth = useAuth();

	return (
		<>
			<Stack
				direction="row"
				alignItems="center"
				spacing={{ xs: 0.5, sm: 1.5 }}
			>
				<ButtonBase component={Link} to='/'>
					<Logo sx={{ filter: 'invert(1)' }} />
				</ButtonBase>
				{title && (
					<Typography variant='h6' noWrap component='div'>
						{ title }
					</Typography>
				)}
			</Stack>
			<Box sx={{ flexGrow: 1 }} />
			<Stack
				direction="row"
				alignItems="center"
				spacing={{ xs: 0.5, sm: 1.5 }}
			>
				<MHidden width="smDown">
					<LightDarkModeToggle />
				</MHidden>
				<AppSelectorPopover />
				{auth.isAuthenticated && (
					<Button
						color='inherit'
						variant='outlined'
						component={Link}
						to='/auth/logout'
					>
						Logout
					</Button>
				)}
				{!auth.isAuthenticated && (
					<Button
						color='inherit'
						variant='outlined'
						component={Link}
						to='/auth/login'
					>
						Login
					</Button>
				)}
			</Stack>
		</>
	);
}
