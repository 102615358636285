import {
	Box, Link, Stack, Typography
} from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Page } from '../../../components/Page';
import NarrowLayout from '../components/NarrowLayout';
import { LoginForm } from '../hocs/LoginForm';

export function Login(): JSX.Element {
	return (
		<Page title='Login'>
			<NarrowLayout>
				<Box sx={{ p: 5 }}>
					<Stack sx={{ mb: 5 }}>
						<Typography variant='h4' gutterBottom>
							Sign in to Proto Suite
						</Typography>
						<Typography sx={{ color: 'text.secondary' }}>
							Enter your details below.
						</Typography>
					</Stack>

					<LoginForm />

					<Typography variant='body2' align='center' sx={{ mt: 3 }}>
						Don’t have an account?&nbsp;
						<Link variant='subtitle2' component={RouterLink} to='/auth/register'>
							Get started
						</Link>
					</Typography>
				</Box>
			</NarrowLayout>
		</Page>
	);
}
